import { getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { customFieldPrefix } from "../components/dashboards/events/custom-field";
import { dateUtil } from "./date-util";
import moment from "moment";
import { apiUrl, client } from "../modules/store";
import {
  CustomFieldState,
  EventCustomFieldDto,
  EventCustomFieldValueDto,
  LynxGridColDef,
} from "types";
import { EntityTypes } from "types/enums-ts";
import { tagParseToChip } from "./helpers/tagParser";
import { CustomFieldTypes } from "types/enums";
export function createCustomField(dto: EventCustomFieldDto) {
  return client.post(`${apiUrl}/customFields`, { ...dto });
}
export function getCustomFields(entityType: EntityTypes) {
  return client.get(`${apiUrl}/customFields?entityType=${entityType}`);
}

export function deleteCustomField(id: number) {
  return client.delete(`${apiUrl}/customFields/${id}`);
}

export function updateCustomFieldSort(dto: EventCustomFieldDto) {
  let url = `${apiUrl}/customFields/sort`;

  return client.post(url, { ...dto });
}

export function patchCustomField(id: number, jPatch: any) {
  return client.patch(`${apiUrl}/customFields/${id}`, jPatch);
}

const getFieldName = (def: EventCustomFieldDto) => {
  if (def.fieldType?.toLowerCase() == "datetime") {
    return `${customFieldPrefix}${def.fieldName}.dateTimeValue`;
  }
  if (def.fieldType?.toLowerCase() == "date") {
    return `${customFieldPrefix}${def.fieldName}.dateValue`;
  }
  if (def.fieldType?.toLowerCase() == "number") {
    return `${customFieldPrefix}${def.fieldName}.numericValue`;
  }
  if (def.fieldType?.toLowerCase() == "checkbox") {
    return `${customFieldPrefix}${def.fieldName}.boolenValue`;
  }
  return `${customFieldPrefix}${def.fieldName}.textValue`;
};
export function transformCustomFieldDefsToColumnDefs(
  customFieldDefs: EventCustomFieldDto[]
): LynxGridColDef[] {
  var columnDefs: LynxGridColDef[] = [];
  if (_.isEmpty(customFieldDefs)) {
    return columnDefs;
  }
  columnDefs = customFieldDefs
    .sort((a, b) => (_.toNumber(a.position) > _.toNumber(b.position) ? 1 : -1))
    .filter((x) => !x.isDeleted)
    .map(
      (def: EventCustomFieldDto) =>
        ({
          field: getFieldName(def),
          headerName: `${def.fieldLabel}${
            def.parentLookupCode
              ? ` (${def.parentLookupCode}${
                  def.childLookupCode ? ` / ${def.childLookupCode}` : ""
                })`
              : ""
          }`,
          eventCategoryId: def.parentLookupId,
          eventSubCategoryId: def.childLookupId,
          importable: true,
          isEventField: true,
          width: 100,
          isMultiSelect:
            def.fieldType?.toLowerCase() == "multiselect" ? true : false,
          type: getColumnTypeFromFieldType(def.fieldType as string),
          sortable: true,
          disabled: def.parentLookupCode ? true : false,
          valueGetter: getCustomFieldValue,
          isCustomField: true,

          valueOptions: def.dropdownValues ? def.dropdownValues.split("|") : [],
          ...(def.fieldType?.toLowerCase() == "multiselect" && {
            filterOperators: getGridSingleSelectOperators().filter(
              (operator) => operator.value !== "isAnyOf"
            ),
          }),
          renderCell: (params: any) =>
            def.fieldType == CustomFieldTypes.TextArea
              ? tagParseToChip(params.value)
              : params.value,
        } as LynxGridColDef)
    );
  return columnDefs;
}

function getColumnTypeFromFieldType(fieldType: string) {
  switch (fieldType.toLowerCase()) {
    case "dropdown":
      return "singleSelect";
    case "multiselect":
      return "singleSelect";
    case "number":
      return "number";
    case "date":
      return "date";
    case "datetime":
      return "dateTime";
    case "checkbox":
      return "boolean";
    default:
      return "string";
  }
}

export function setupInitialCustomFieldState(
  eventCustomFields: EventCustomFieldDto[],
  existingCustomFieldValues: EventCustomFieldValueDto[]
) {
  var state: { [key: string]: CustomFieldState }[] = [];
  state = eventCustomFields.map((field: EventCustomFieldDto) => {
    var existingValue =
      existingCustomFieldValues &&
      existingCustomFieldValues.find((x) => x.fieldName == field.fieldName);
    return {
      [`${field.fieldName}`]: {
        numericValue: existingValue ? existingValue.numericValue : "",
        textValue: existingValue ? existingValue.textValue : "",
        plainTextValue: existingValue ? existingValue.plainTextValue : "",
        booleanValue: existingValue ? existingValue.booleanValue : "",
        dateTimeValue: existingValue
          ? existingValue.dateTimeValue
            ? moment(existingValue.dateTimeValue).format("YYYY-MM-DD HH:mm")
            : ""
          : "",
        dateValue: existingValue
          ? existingValue.dateValue
            ? dateUtil.convertDateOnlyToLocal(existingValue.dateValue)
            : ""
          : "",
      } as CustomFieldState,
    };
  });
  var requiredCustomFields = eventCustomFields
    .filter((x) => x.isRequired && !x.isDeleted)
    .map((field) => ({
      [`${field.fieldName}Error`]: "",
    }));
  var stateToReturn = Object.assign(
    {},
    ...state,
    ...requiredCustomFields
  ) as any;
  return stateToReturn;
}

export function transformCustomFieldState(
  eventCustomFields: EventCustomFieldDto[],
  customFieldState: { [key: string]: CustomFieldState },
  parentLookupId: number,
  childLookupId: number
) {
  let customFieldArray: EventCustomFieldValueDto[] = [];

  eventCustomFields.forEach((f) => {
    const field = customFieldState[f.fieldName as string];
    if (!field) {
      return;
    }
    if (
      (f.parentLookupId != null && f.parentLookupId != parentLookupId) ||
      (f.childLookupId != null && f.childLookupId != childLookupId)
    ) {
      return;
    }
    if (_.toLower(f.fieldType) == "datetime" && field && field.dateTimeValue) {
      customFieldArray.push({
        fieldName: f.fieldName,
        dateTimeValue: (field.dateTimeValue =
          field.dateTimeValue == ""
            ? ""
            : moment(field.dateTimeValue).utc().format()),
      });
    } else {
      customFieldArray.push({
        fieldName: f.fieldName,
        textValue: field.textValue ?? undefined,
        booleanValue: field.booleanValue ?? undefined,
        dateValue: field.dateValue ?? undefined,
        numericValue: field.numericValue ?? undefined,
        plainTextValue: field.plainTextValue ?? undefined,
      });
    }
  });
  return customFieldArray;
}

function getCustomFieldValue(params: any, row: any, column: any) {
  let fieldName = column.field.substring(
    column.field.indexOf(".") + 1,
    column.field.lastIndexOf(".")
  );

  const field = row.eventCustomFieldValues.find(
    (x: any) => x.fieldName == fieldName
  );
  if (field) {
    if (column.type == "dateTime" && field && field.dateTimeValue) {
      return dateUtil.convertColumnDateTimeToLocal(field.dateTimeValue);
    }
    if (column.type == "date" && field && field.dateValue) {
      return dateUtil.convertColumnDateOnlyToLocal(field.dateValue);
    }
    if (column.type == "boolean" && field && field.booleanValue) {
      return field.booleanValue;
    }
    if (column.type == "number" && field && field.numericValue) {
      return field.numericValue;
    }

    if (column.isMultiSelect && field && field.textValue) {
      return field.textValue ? field.textValue.split("|").join(", ") : "";
    }
    if (field && field.textValue) {
      return field.textValue;
    }
  }

  return "";
}
