import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Grid, Form } from "components/lynx-components";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { LynxDataGrid } from "../../../data-grid/lynx-data-grid";
import {
  getCustomerPortal,
  getUserHistory,
  toggleCustomerPortalStatus,
} from "../../../../services/customer-portal-service";
import _ from "lodash";
import { LynxDialog } from "../../../lynx-dialog";
import Tooltip from "@mui/material/Tooltip";

import { validationService } from "../../../../services";
import { LynxTextArea } from "../../../form-controls/lynx-form-controls";
import { dateUtil } from "../../../../services/date-util";
import { EntityTypes, PortalUserStatuses } from "types/enums";
import { userHistoryColumns } from "./user-history-columns";
import { navigateTo } from "services/navigation-service";
import useAlert from "hooks/useAlert";
import lynxColors from "modules/lynxColors";
import { Chip } from "@mui/material";
import queryString from "query-string";
import { useSelector } from "react-redux";
import {
  useAddPortalUserMutation,
  useApproveRejectPortalUserMutation,
  useDeletePortalUserMutation,
  useLazyGetPortalUserByIdQuery,
  useGetPortalUsersQuery,
  useUpdatePortalUserAdminMutation,
  useUpdatePortalUserMutation,
} from "services/rtkApi/endpoints/users";
import { useGetUserPortalHistoryQuery } from "services/rtkApi/endpoints/customerPortal";
export function CustomerPortalSettings() {
  const [addTrigger] = useAddPortalUserMutation();
  const [approveRejectTrigger] = useApproveRejectPortalUserMutation();
  const [deleteTrigger] = useDeletePortalUserMutation();
  const [getPortalUserTrigger] = useLazyGetPortalUserByIdQuery();
  const [updateTrigger] = useUpdatePortalUserAdminMutation();
  const initialFormState = {
    email: "",
    emailError: "",
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    notes: "",
  };
  const history = useHistory();
  const [customerPortal, setCustomerPortal] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showToggleWarning, setShowToggleWarning] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [formState, setFormState] = useState(initialFormState);
  const [error, setError] = useState(null);
  const [gridLoading, setGridLoading] = useState(false);
  const [createdUser, setCreatedUser] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [approvalMessage, setApprovalMessage] = useState(null);
  const isPendingRegistration =
    !_.isEmpty(selectedUser) && !selectedUser.registrationCompletedDateTimeUtc;

  const isRejected =
    !_.isEmpty(selectedUser) &&
    selectedUser.registrationCompletedDateTimeUtc != null &&
    selectedUser.registrationRejectedDateTimeUtc != null;
  const isPendingApproval =
    !_.isEmpty(selectedUser) &&
    !selectedUser.registrationApprovedDateTimeUtc &&
    selectedUser.registrationCompletedDateTimeUtc != null &&
    !isRejected;
  const { showAlert } = useAlert();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const canApproveUsers = account && account.canApprovePortalRegistrations;
  useEffect(() => {
    const qs = queryString.parse(location.search);
    if (qs.userToApprove) {
      var userId = qs.userToApprove;
      getPortalUserTrigger(userId).then((res) => {
        if (res.data) {
          setSelectedUser(res.data);
          setFormStateFromUser(res.data);
          setShowUserModal(true);
        }
      });
    }
  }, []);

  const removeQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("userToApprove")) {
      queryParams.delete("userToApprove");
      history.replace({ search: queryParams.toString() });
    }
  };

  let defaultColumns = [
    {
      field: "Actions",
      headerName: "",
      filterable: false,
      sortable: false,
      width: 50,
      type: "actions",
      resizable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit User">
              <IconButton
                onClick={() => {
                  setSelectedUser(params.row);
                  setFormStateFromUser(params.row);
                  setShowUserModal(true);
                }}
                aria-label="Edit User"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
      type: "string",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      type: "string",
      renderCell: (params) => (
        <div>
          {params.value && (
            <>
              <i
                className={"fe fe-circle "}
                style={{ color: statusColorSwitch(params.value) }}
              />
              &nbsp;{params.value}
            </>
          )}
        </div>
      ),
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 250,
      type: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 250,
      type: "string",
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 250,
      type: "string",
    },
    {
      field: "isActive",
      headerName: "Active?",
      width: 250,
      type: "boolean",
    },
    {
      field: "createdDateTimeUtc",
      headerName: "Created Date",
      width: 150,
      type: "dateTime",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "registrationCompletedDateTimeUtc",
      headerName: "Registered Date",
      width: 150,
      type: "dateTime",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
  ];
  useEffect(() => {
    getCustomerPortal()
      .then((res) => {
        setCustomerPortal(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const setFormStateFromUser = (user) => {
    setFormState({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      notes: user.notes,
      isActive: user.isActive,
    });
  };

  const toggleStatus = () => {
    toggleCustomerPortalStatus(!customerPortal.isEnabled)
      .then((res) => {
        setCustomerPortal(res.data);
        showAlert(
          "success",
          `Portal access ${customerPortal.isEnabled ? "disabled" : "enabled"}.`
        );
      })
      .finally(() => {
        setShowToggleWarning(false);
      });
  };
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const userForm = (
    <Grid>
      {!showHistory ? (
        <>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Status">
                <div className="mb-2">{getStatusChip(selectedUser.status)}</div>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Email" isRequired>
                <Form.Input
                  name="email"
                  disabled={!_.isEmpty(selectedUser)}
                  onChange={handleInputChange}
                  value={formState.email}
                  error={formState.emailError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="First name" isRequired>
                <Form.Input
                  name="firstName"
                  disabled={isPendingRegistration}
                  error={formState.firstNameError}
                  onChange={handleInputChange}
                  value={formState.firstName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Last name" isRequired>
                <Form.Input
                  name="lastName"
                  disabled={isPendingRegistration}
                  error={formState.lastNameError}
                  onChange={handleInputChange}
                  value={formState.lastName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Notes (internal purposes only)">
                <LynxTextArea
                  name="notes"
                  onChange={handleInputChange}
                  value={formState.notes}
                ></LynxTextArea>
              </Form.Group>
            </Grid.Col>
            {!_.isEmpty(selectedUser) &&
              !isPendingApproval &&
              !isPendingRegistration && (
                <Grid.Col md={12} width={12}>
                  <Form.Group label="">
                    <Button
                      variant="outlined"
                      className="mt-2"
                      onClick={() => {
                        setShowHistory(true);
                      }}
                    >
                      View User History
                    </Button>
                  </Form.Group>
                </Grid.Col>
              )}

            {isPendingRegistration && (
              <Grid.Col>
                <div className="text-red mb-2">{`User is pending registration. Registration link: ${window.location.origin}/register/${selectedUser.registrationGuid}`}</div>
              </Grid.Col>
            )}
            {!_.isEmpty(selectedUser) &&
              !isPendingApproval &&
              !isPendingRegistration &&
              !isRejected && (
                <Grid.Col md={12} width={12}>
                  <Form.Group>
                    <Form.Checkbox
                      label="Active"
                      name="isActive"
                      onChange={handleInputChange}
                      checked={formState.isActive}
                    />
                  </Form.Group>
                </Grid.Col>
              )}
          </Grid.Row>
        </>
      ) : (
        <Grid.Row>
          <Grid.Col width={12}>
            <div style={{ height: "800px" }}>
              {!_.isEmpty(userHistoryColumns) && (
                <LynxDataGrid
                  autoHeight
                  onlyShowTable
                  columns={userHistoryColumns}
                  useQuery={useGetUserPortalHistoryQuery}
                  localStorageName="org_portal_user_history"
                  entityType={EntityTypes.CustomerPortalUserHistory}
                  useQueryParams={{
                    userId: selectedUser.id,
                  }}
                />
              )}
            </div>
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid>
  );

  const handleSaveUser = () => {
    if (!validateDataForSave()) {
      return;
    }
    setGridLoading(true);
    var dtoToSave = { ...formState };
    if (isPendingApproval) {
      handleApproveRejectUser(true, dtoToSave);
    } else if (!_.isEmpty(selectedUser)) {
      updateTrigger({ id: selectedUser.id, dto: dtoToSave }).then((res) => {
        showAlert("success", "User updated successfully.");
        setCreatedUser(null);

        setShowUserModal(false);
        setFormState(initialFormState);
        removeQueryParam();
        setSelectedUser({});
        setGridLoading(false);
        setFormState(initialFormState);
      });
    } else {
      addTrigger(dtoToSave).then((res) => {
        if (res.data) {
          setCreatedUser(res.data);
        }
        setGridLoading(false);
        setFormState(initialFormState);
      });
    }
  };
  const validateDataForSave = (saveAction) => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateEmailValue(
      newState.email,
      newState,
      "emailError",
      true
    );
    if (!isPendingRegistration) {
      validationService.validateRequiredField(
        newState,
        "firstName",
        "firstNameError",
        "First name"
      );

      validationService.validateRequiredField(
        newState,
        "lastName",
        "lastNameError",
        "Last name"
      );
    }

    let errorFields = ["emailError"];

    if (!isPendingRegistration) {
      errorFields = [...errorFields, "firstNameError", "lastNameError"];
    }

    isFormValid = !validationService.hasError(newState, ...errorFields);

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleApproveRejectUser = (isApproved, userDto) => {
    approveRejectTrigger({
      id: selectedUser.id,
      dto: userDto,
      isApproved: isApproved,
    }).then((res) => {
      setSelectedUser({});
      if (isApproved) {
        setApprovalMessage(
          "User has been approved. User will recieve an email with a link to login."
        );
      } else {
        setApprovalMessage(
          "User has been rejected. User will recieve an email informing them to contact the customer for more information."
        );
      }
      setGridLoading(false);
      setSelectedUser({});
      setShowUserModal(false);
      setFormState(initialFormState);
      removeQueryParam();
    });
  };

  return (
    <Grid>
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
            <Grid.Col width={12}>
              <div className="d-flex h-100">
                <Typography
                  className="align-self-center"
                  variant="h3"
                  component="div"
                >
                  Customer Portal Settings
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/settings")}
                >
                  Settings
                </Link>

                <Typography color="text.primary">
                  Customer Portal Settings
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Portal Access
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                Enable or disable portal access for all portal users.
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card>
              <Card.Body>
                <Button
                  variant="contained"
                  disabled={!canApproveUsers}
                  color={customerPortal.isEnabled ? "error" : "primary"}
                  onClick={() => setShowToggleWarning(true)}
                >
                  {customerPortal.isEnabled ? "Disable" : "Enable"} Customer
                  Portal
                </Button>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Portal Users
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                View portal users, registration status, and enable or disable
                user access.
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card className="mb-0">
              <Card.Body className="p-0">
                <Button
                  variant="outlined"
                  onClick={() => setShowUserModal(true)}
                  className="mt-2 ml-2 mb-2"
                >
                  Add Portal User
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigateTo(history, "/settings/portal/history")
                  }
                  className="mt-2 ml-2 mb-2"
                >
                  View User History
                </Button>
              </Card.Body>
            </Card>
            <div style={{ height: 630, width: "100%" }}>
              {!_.isEmpty(defaultColumns) && (
                <LynxDataGrid
                  columns={defaultColumns}
                  useQuery={useGetPortalUsersQuery}
                  entityType={"User"}
                  passEntityOnClick={true}
                  disableAddButton={true}
                  localStorageName="users"
                  onlyShowTable
                />
              )}
            </div>
          </Grid.Col>
        </Grid.Row>
      </Dimmer>
      <LynxDialog
        dividers
        open={showToggleWarning}
        title={
          customerPortal.isEnabled
            ? "Disable Portal Access?"
            : "Enable Portal Access?"
        }
        description={
          customerPortal.isEnabled
            ? "Are you sure you want to disable portal access? This will immediately disable access for all portal users."
            : "Are you sure you want to enable portal access? This will immediately enable access for all portal users."
        }
        handleClose={() => {
          setShowToggleWarning(false);
        }}
        handleConfirm={toggleStatus}
      />
      {showUserModal && (
        <LynxDialog
          dividers
          open={showUserModal}
          title={
            showHistory ? (
              <div>
                <IconButton
                  aria-label="back_button"
                  className="mr-auto"
                  onClick={() => {
                    setShowHistory(false);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                User History
              </div>
            ) : _.isEmpty(selectedUser) ? (
              "Add Portal User"
            ) : isPendingApproval ? (
              "Approve Portal User"
            ) : (
              "Edit Portal User"
            )
          }
          saveButtonColor={isPendingApproval ? "success" : "primary"}
          maxWidth={showHistory ? "xl" : null}
          fullWidth={showHistory ? true : false}
          saveButtonText={
            _.isEmpty(selectedUser)
              ? "Add"
              : isPendingApproval
              ? "Save and Approve"
              : "Save"
          }
          deleteButtonText={isPendingApproval ? "Save and Reject" : "Delete"}
          dialogContent={userForm}
          handleClose={() => {
            setSelectedUser({});
            setShowHistory(false);
            setFormState(initialFormState);
            setShowUserModal(false);
            setFormState(initialFormState);
            removeQueryParam();
          }}
          handleSave={
            !canApproveUsers && isPendingApproval ? null : handleSaveUser
          }
          handleDelete={
            !canApproveUsers
              ? null
              : isPendingApproval
              ? () => handleApproveRejectUser(false, formState)
              : !_.isEmpty(selectedUser) &&
                (selectedUser.registrationCompletedDateTimeUtc == null ||
                  isRejected)
              ? () => {
                  setGridLoading(true);
                  deleteTrigger(selectedUser.id).then(() => {
                    showAlert("success", "Portal user deleted.");
                    setShowUserModal(false);
                    removeQueryParam();
                    setGridLoading(false);
                    setFormState(initialFormState);
                    setSelectedUser({});
                  });
                }
              : null
          }
        />
      )}
      {error && (
        <LynxDialog
          open={error != null}
          title={
            _.isEmpty(selectedUser) ? "Error Adding User" : "Error Saving User"
          }
          description={error}
          handleConfirm={() => {
            setError(null);
          }}
        />
      )}
      {approvalMessage && (
        <LynxDialog
          open={approvalMessage}
          title={"User Approval/Rejection Complete"}
          description={approvalMessage}
          handleConfirm={() => {
            setApprovalMessage(null);
          }}
        />
      )}
      {createdUser && (
        <LynxDialog
          open={createdUser != null}
          title={"Portal User Added"}
          description={`The user will be recieve an email with the following registration link: ${window.location.origin}/register/${createdUser.registrationGuid} . You can check the portal user grid for their registration status.`}
          handleConfirm={() => {
            setCreatedUser(null);
            setFormState(initialFormState);
            setShowUserModal(false);
            removeQueryParam();
          }}
        />
      )}
    </Grid>
  );
}

const statusColorSwitch = (status) => {
  switch (status) {
    case PortalUserStatuses.PendingApproval:
      return lynxColors.harvestOrange;
    case PortalUserStatuses.PendingRegistration:
    case PortalUserStatuses.Inactive:
    case PortalUserStatuses.Rejected:
      return lynxColors.error;
    default:
      return lynxColors.primary;
  }
};

const getStatusChip = (status) => {
  if (status === PortalUserStatuses.PendingApproval) {
    return (
      <Chip
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
        label={PortalUserStatuses.PendingApproval}
        color="secondary"
      />
    );
  }
  if (
    [
      PortalUserStatuses.PendingRegistration,
      PortalUserStatuses.Rejected,
      PortalUserStatuses.Inactive,
    ].includes(status)
  ) {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.error, color: "white" }}
      />
    );
  }
};
