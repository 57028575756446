import axios from "axios";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import account from "reducers/account";
import auth from "reducers/auth";
import organization from "reducers/organization";
import portal from "reducers/portal";
import axiosMiddleware from "redux-axios-middleware";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  apiService,
  mainUrl,
  refreshAccessToken,
} from "services/rtkApi/apiService";
import lookupReducer from "../reducers/lookups";
import { LocalStorageKeys } from "../types/enums";
import { combineReducers } from "redux";
import { rtkQueryErrorLogger } from "./middleware/logger";
import alerts from "reducers/alerts";

//update this to be devUrl, betaUrl, prodUrl or demoUrl depending on backend environment

export const apiUrl = mainUrl + "api";

export const client = axios.create({
  baseURL: apiUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});
// Track if a refresh request is already in progress
let isRefreshing = false;
let refreshSubscribers: ((newToken: string) => void)[] = [];

// Function to broadcast the new token to all subscribers
const onRefreshed = (newToken: string) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};
client.interceptors.request.use((config) => {
  const token = localStorage.getItem(LocalStorageKeys.AccessToken);
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
});
// Axios response interceptor to handle 401 errors
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // If the error is a 401 and the request hasn't been retried
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !isRefreshing &&
      !error.response.data
    ) {
      originalRequest._retry = true;
      isRefreshing = true;
      try {
        // Update the access token in localStorage
        const newAccessToken = await refreshAccessToken();
        isRefreshing = false;
        onRefreshed(newAccessToken);

        // Retry the original request with the new token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return client(originalRequest);
      } catch (refreshError) {
        isRefreshing = false;
        // Handle refresh token failure (e.g., redirect to login)
        console.error("Refresh token failed:", refreshError);
        localStorage.removeItem(LocalStorageKeys.AccessToken);
        // Optionally, dispatch a logout action
        return Promise.reject(refreshError);
      }
    }

    // If a refresh is already in progress, queue the request
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !error.response.data
    ) {
      return new Promise((resolve, reject) => {
        refreshSubscribers.push((newToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          resolve(client(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);
const persistConfig = {
  key: "lookups",
  storage,
};

const lookupPersistedReducer = persistReducer(persistConfig, lookupReducer);
const rootReducer = combineReducers({
  auth,
  account,
  alerts,
  organization,
  portal,
  lookups: lookupPersistedReducer,
  [apiService.reducerPath]: apiService.reducer,
});
export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false })
      .concat(thunk, axiosMiddleware(client))
      .concat(apiService.middleware)
      .concat(rtkQueryErrorLogger);
  },
  devTools: process.env.NODE_ENV !== "production",
});
export type AppStore = typeof appStore;
export const persistor = persistStore(appStore);
//export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof appStore.dispatch;
