import EmailIcon from "@mui/icons-material/Email";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import GasMeterOutlinedIcon from "@mui/icons-material/GasMeterOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import MonitorOutlinedIcon from "@mui/icons-material/MonitorOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import UploadIcon from "@mui/icons-material/Upload";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArticleIcon from "@mui/icons-material/Article";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";

import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import localforage from "localforage";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { loadAccount } from "../actions/account";
import {
  getOrganizationUserId,
  hasAnyEventRole,
  hasAnyMonitoringRole,
  loggedIn,
  logoutUser,
  roleMatch,
  swithOrganizationUser,
} from "../actions/auth";
import { loadUserOrg } from "../actions/organization";
import LynxLogoBW from "../images/Lynx-logomark-bw-rgb.svg";
import LynxLogo from "../images/Lynx-logomark-colour-rgb.svg";
import {
  default as colors,
  default as lynxColors,
} from "../modules/lynxColors";

import {
  getUserNoitifications,
  markNotificationAsRead,
} from "../services/notification-service";
import {
  eventSyncStore,
  offlineDataService,
} from "../services/offlineDataService";
import { UrlService, urlService } from "../services/url-service";
import { mainTourSteps } from "../tours/main-tour";
import useOnlineStatus from "./../hooks/useOnlineStatus";
import { getUserGuide } from "./../services/accounts";
import { readAllNotifications } from "./../services/notification-service";
import { TourTypes, UserRoles } from "./../types/enums";
import { LynxDialog } from "./lynx-dialog";
import { LynxTour } from "./lynx-tour";
import "./navbar.css";
import { NotificationsMenu } from "./notifications-menu";
import useWindowDimensions from "./../hooks/useWindowDimensions";
import { getUsersLookups } from "services/users";
import { setUsers } from "reducers/lookups";
import { Divider } from "@mui/material";
import { Check, InsertDriveFile } from "@mui/icons-material";
import { navigateTo } from "services/navigation-service";
import useAlert from "hooks/useAlert";

const NavbarTop = styled.div`
  background-color: ${(props) => (props.isMapModule ? "#0c0d0d" : "#ffffff")};
  position: fixed;
  left: 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
  top: 0;
  border-bottom: 1px solid #ced4da;
`;

const MenuBottonSpan = styled.span`
  background-color: ${(props) => (props.isMapModule ? "#ffffff" : "#0c0d0d")};
  border-radius: 1px;
  display: block;
  left: 5px;
  width: 18px;
  height: 3px;
  margin-bottom: 3px;
  position: relative;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
`;

const RightContainer = styled.div`
  position: relative;
  float: right;
  top: 14px;
  margin-right: 30px;
`;

const LeftContainer = styled.div`
  margin-left: 10px;
  position: relative;
  float: left;
  top: 14px;
  padding-right: 30px;
`;
const LogoDiv = styled.div`
  margin-left: 20px;
  position: relative;
  float: left;
  top: 14px;
  margin-right: -11rem;
  height: 50px;
`;

const MenuDiv = styled.div`
  position: relative;
  float: right;
  top: 14px;
  height: 50px;
  margin-right: 30px;
  color: #0c0d0d;
`;

const NavbarSelection = styled.div`


margin: 0px 10px 0px 10px
display: inline-block;
color: #0c0d0d;
font-size: 16px;
cursor: pointer;
`;
const LogoContainer = styled.img`
  height: 300px;
  margin-left: -6rem;
  margin-top: -8.75rem;
`;

function Navbar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElAccount, setAnchorElAccount] = React.useState(null);
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);

  const [initials, setInitials] = React.useState(null);

  const [isOfflineReady, setIsOfflineReady] = React.useState(true);
  const [unsyncedEventCount, setUnsyncedEventCount] = React.useState(0);
  const [showSyncDialog, setShowSyncDialog] = React.useState(false);
  const [showExpired, setShowExpired] = React.useState(false);
  const [showSupport, setShowSupport] = React.useState(false);
  const [showSyncLoading, setShowSyncLoading] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  var organization = useSelector((state) => state.organization);
  const [anchorElNotifications, setAnchorElNotifications] =
    React.useState(null);
  const openMenu = Boolean(anchorEl);
  const openAccount = Boolean(anchorElAccount);
  const openHelp = Boolean(anchorElHelp);
  const openNotificaions = Boolean(anchorElNotifications);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isOffline = !useOnlineStatus();
  var orgSettings = useSelector((state) => state.organization);
  var account = useSelector((state) => state.account);
  const { width } = useWindowDimensions();
  const { showAlert } = useAlert();
  var auth = useSelector((state) => state.auth);
  React.useEffect(() => {
    if (
      loggedIn() &&
      !roleMatch([UserRoles.PortalUser]) &&
      !auth.isFetching &&
      !urlService.isPublicScreen()
    ) {
      dispatch(loadAccount());
      dispatch(loadUserOrg());
      loadNotifications();
      loadUsers();
      checkIfOfflineDataExists();
    }
  }, [loggedIn(), auth.isFetching]);

  React.useEffect(() => {
    if (!urlService.isPublicScreen()) {
      if (isOffline && !location.pathname.includes("/dasboard/events")) {
        showAlert("error", "Currently offline.");
      }
      if (!isOffline) {
        setTimeout(function () {
          checkIfUnsyncedEvents();
        }, 3000);
      }
    }
  }, [isOffline]);

  React.useEffect(() => {
    getAvatarName();
  }, [account]);

  React.useEffect(() => {
    if (
      !_.isEmpty(orgSettings) &&
      orgSettings.licenseExpirationDateTimeUtc &&
      orgSettings.licenseExpirationDateTimeUtc != null
    ) {
      var expirationDate = moment(orgSettings.licenseExpirationDateTimeUtc);

      if (expirationDate < moment()) {
        setShowExpired(true);
      }
    }
  }, [orgSettings]);

  React.useEffect(() => {}, []);

  const loadUsers = () => {
    getUsersLookups()
      .then((res) => {
        dispatch(setUsers(res.data));
      })
      .catch(() => {});
  };

  const loadNotifications = () => {
    getUserNoitifications().then((res) => {
      setNotifications(res.data);
    });
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const handleNotificationsClick = (e) => {
    setAnchorElNotifications(e.currentTarget);
  };

  const checkIfUnsyncedEvents = () => {
    localforage.getItem(eventSyncStore).then((res) => {
      if (res != null) {
        const totalEventsToSync = res.length;
        setUnsyncedEventCount(totalEventsToSync);
      }
    });
  };

  const checkIfOfflineDataExists = () => {
    let isReady = true;

    offlineDataService.checkIfOfflineIsReady().then((res) => {
      if (!res) {
        isReady = false;
      }
      setIsOfflineReady(isReady);
    });
  };

  const doLogout = () => {
    props.logoutUser();
    history.push("/authorize");
  };
  const handleMenuClick = (event) => {
    setAnchorElAccount(null);
    if (openMenu) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleAccountClick = (event) => {
    setAnchorEl(null);
    if (openAccount) {
      handleCloseAccount();
    } else {
      setAnchorElAccount(event.currentTarget);
    }
  };

  const handleHelpClick = (event) => {
    setAnchorElHelp(null);
    if (openHelp) {
      handleCloseHelp();
    } else {
      setAnchorElHelp(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const handleCloseHelp = () => {
    setAnchorElHelp(null);
  };
  const getAvatarName = () => {
    let nameString = "";
    if (account.firstName) nameString += _.first(account.firstName);
    if (account.lastName) nameString += _.first(account.lastName);
    if (nameString != "") {
      setInitials(nameString);
    }
  };
  const handlePrepOffline = () => {
    showAlert("success", "Syncing data...");
    offlineDataService.prepareForOffline();
    setTimeout(function () {
      checkIfOfflineDataExists();
    }, 6000);
  };

  const handleSyncEvents = () => {
    setShowSyncDialog(true);
  };

  const handleConfirmSync = () => {
    setShowSyncLoading(true);
    offlineDataService.handleSyncEvents().then((res) => {
      if (res == true) {
        setTimeout(function () {
          checkIfUnsyncedEvents();
        }, 2000);

        offlineDataService.reloadEvents();

        setShowSyncDialog(false);
        setShowSyncLoading(false);
      }
    });
  };

  const handleMarkAsRead = (notification) => {
    handleNotificationClose();
    if (!notification.isRead) {
      markNotificationAsRead(notification.id, true).then((res) => {
        setNotifications(res.data);
      });
    }
  };

  const handleMarkAllAsRead = () => {
    readAllNotifications().then((res) => {
      setNotifications(res.data);
    });
  };

  const handleNotificationReadChange = (id, isRead) => {
    markNotificationAsRead(id, isRead).then((res) => {
      setNotifications(res.data);
    });
  };

  const handleNotificationClose = () => {
    setAnchorElNotifications(null);
  };

  const getTour = () => {
    return (
      <LynxTour
        title={"Welcome to the Lynx Platform!"}
        description={
          "The Lynx Platform is the easiest way to manage your organization’s health, safety, environmental and community data. This tour will show you around the platform, how to navigate, add data, and a number of other useful functions. Let’s go!"
        }
        steps={mainTourSteps}
        flagField={"initialTourCompletedDateTimeUtc"}
        tourType={TourTypes.Initial}
      />
    );
  };

  const getHelpMenu = () => {
    return (
      <>
        <MenuDiv className={`avatar-div  ${"mr-0"} help-menu`}>
          <Tooltip title={"Help"}>
            <IconButton
              onClick={handleHelpClick}
              aria-label="help"
              size="small"
            >
              {" "}
              <HelpOutlineIcon
                sx={{ fontSize: 30, color: lynxColors.harvestOrange }}
              />
            </IconButton>
          </Tooltip>
        </MenuDiv>
        <Menu
          className="nav-menu"
          anchorEl={anchorElHelp}
          open={openHelp}
          disableScrollLock={true}
          onClose={handleCloseHelp}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* <MenuItem
            onClick={() => {
              //history.push("/newAdmin");
       
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText>Help Guide</ListItemText>
          </MenuItem> */}

          <MenuItem
            onClick={() => {
              setShowSupport(true);
              handleCloseHelp();
            }}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText>
              <a>Contact Support</a>
            </ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              showAlert("success", "Downloading user guide.");
              handleCloseHelp();
              getUserGuide().then((res) => {
                saveAs(res.data, "Lynx ESG Platform User Guide.pdf");
              });
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText>
              <a>Download User Guide</a>
            </ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const handleSwitchOrg = (id) => {
    props.swithOrganizationUser(id).then(() => {
      navigateTo(history, "/dashboard");
      handleCloseAccount();
    });
  };

  const getUserAccountMenu = () => {
    return (
      <>
        <MenuDiv className="pointer avatar-div" onClick={handleAccountClick}>
          <Avatar
            src="/broken-image.jpg"
            sx={{ bgcolor: colors.harvestOrange }}
          >
            {initials}
          </Avatar>
        </MenuDiv>
        <>
          <MenuDiv className="avatar-div mr-3">
            <Tooltip title="Notifications">
              <IconButton
                onClick={handleNotificationsClick}
                aria-label="Notifications"
                size="small"
              >
                <Badge
                  badgeContent={notifications.filter((x) => !x.isRead).length}
                  color="primary"
                  className="pointer"
                >
                  <NotificationsIcon color="action" />
                </Badge>
              </IconButton>
            </Tooltip>
          </MenuDiv>
          <Menu
            id="basic-menu"
            anchorEl={anchorElNotifications}
            open={openNotificaions}
            onClose={handleNotificationsClose}
          >
            <NotificationsMenu
              notifications={notifications}
              handleMarkAsRead={handleMarkAsRead}
              handleMarkAllAsRead={handleMarkAllAsRead}
              handleNotificationClose={handleNotificationClose}
              handleNotificationReadChange={handleNotificationReadChange}
            />
          </Menu>
        </>
        <Menu
          className="nav-menu"
          anchorEl={anchorElAccount}
          open={openAccount}
          disableScrollLock={true}
          onClose={handleCloseAccount}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {roleMatch([UserRoles.SuperAdmin]) && !isOffline && (
            <MenuItem
              onClick={() => {
                history.push("/admin/customers");
                handleCloseAccount();
              }}
            >
              <ListItemText>Admin</ListItemText>
            </MenuItem>
          )}

          {!isOffline && (
            <MenuItem
              onClick={() => {
                history.push("/account");
                handleCloseAccount();
              }}
            >
              <ListItemText>Profile</ListItemText>
            </MenuItem>
          )}
          <Divider />
          {props.account.organizationUsers.length > 1 && (
            <Box>
              {_.sortBy(props.account.organizationUsers, [
                "organizationName",
              ]).map((orgUser) => (
                <MenuItem
                  key={orgUser.id}
                  onClick={
                    _.toNumber(getOrganizationUserId()) == orgUser.id
                      ? null
                      : () => handleSwitchOrg(orgUser.id)
                  }
                >
                  <ListItemIcon>
                    {_.toNumber(getOrganizationUserId()) == orgUser.id && (
                      <Check />
                    )}
                  </ListItemIcon>
                  {orgUser.organizationName}
                </MenuItem>
              ))}
              <Divider />
            </Box>
          )}

          <MenuItem
            onClick={() => {
              doLogout();

              handleCloseAccount();
            }}
          >
            <ListItemText> Sign out</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const getOfflineSyncOptions = () => {
    return (
      <>
        {" "}
        <MenuDiv className="avatar-div mr-1">
          <Tooltip
            title={
              isOffline
                ? "Unable to sync when offline"
                : isOfflineReady
                ? "Ready for offline use"
                : "Press to sync data for offline use"
            }
          >
            <IconButton
              onClick={() => {
                handlePrepOffline();
              }}
              aria-label="Not_synced"
              size="small"
              disabled={isOffline}
            >
              {" "}
              <SyncIcon
                sx={{
                  color: isOffline
                    ? "gray"
                    : isOfflineReady
                    ? lynxColors.success
                    : lynxColors.error,
                }}
              />
            </IconButton>
          </Tooltip>
        </MenuDiv>
        {!isOffline && (
          <MenuDiv className="avatar-div mr-1">
            <Tooltip title={"Sync Events"}>
              <IconButton
                onClick={() => {
                  handleSyncEvents();
                }}
                aria-label="Not_synced"
                size="small"
                disabled={isOffline}
              >
                <Badge badgeContent={unsyncedEventCount} color="primary">
                  <UploadIcon color="primary" />
                </Badge>
              </IconButton>
            </Tooltip>
          </MenuDiv>
        )}
      </>
    );
  };

  const getMobileMenu = () => {
    return (
      <>
        <MenuDiv className="menu-button pointer" onClick={handleMenuClick}>
          <MenuBottonSpan></MenuBottonSpan>
          <MenuBottonSpan></MenuBottonSpan>
          <MenuBottonSpan></MenuBottonSpan>Menu
        </MenuDiv>
        <Menu
          className="nav-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push("/dashboard");
              handleClose();
            }}
          >
            <ListItemIcon>
              <MonitorOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </MenuItem>

          {!isOffline && (
            <div>
              {roleMatch([UserRoles.Admin]) && (
                <MenuItem
                  onClick={() => {
                    history.push("/assets");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Assets</ListItemText>
                </MenuItem>
              )}
              {hasAnyEventRole() && (
                <MenuItem
                  onClick={() => {
                    history.push("/events");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <EventOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Events</ListItemText>
                </MenuItem>
              )}
              {roleMatch([UserRoles.IncidentsUser]) && (
                <MenuItem
                  onClick={() => {
                    history.push("/incidents");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <WarningAmberIcon />
                  </ListItemIcon>
                  <ListItemText>Incidents</ListItemText>
                </MenuItem>
              )}
              {(hasAnyEventRole() || roleMatch([UserRoles.IncidentsUser])) && (
                <MenuItem
                  onClick={() => {
                    history.push("/actions");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <PendingActionsIcon />
                  </ListItemIcon>
                  <ListItemText>Actions</ListItemText>
                </MenuItem>
              )}
              {roleMatch([UserRoles.InspectionsUser]) && (
                <MenuItem
                  onClick={() => {
                    history.push("/inspections");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <ContentPasteIcon />
                  </ListItemIcon>
                  <ListItemText>Inspections</ListItemText>
                </MenuItem>
              )}

              {roleMatch([UserRoles.InspectionsUser]) && (
                <MenuItem
                  onClick={() => {
                    history.push("/equipment");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <ConstructionOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Equipment</ListItemText>
                </MenuItem>
              )}

              {hasAnyMonitoringRole() && (
                <MenuItem
                  onClick={() => {
                    history.push("/monitoring");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <GasMeterOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Monitoring</ListItemText>
                </MenuItem>
              )}
              {roleMatch([UserRoles.EnergyUser]) && (
                <MenuItem
                  onClick={() => {
                    history.push("/energy-logs");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <FactoryIcon />
                  </ListItemIcon>
                  <ListItemText>Energy Logs</ListItemText>
                </MenuItem>
              )}
              {orgSettings.hasPermitFeature && (
                <MenuItem
                  onClick={() => {
                    history.push("/permits");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText>Permits</ListItemText>
                </MenuItem>
              )}
              {hasAnyEventRole() && (
                <MenuItem
                  onClick={() => {
                    history.push("/contacts");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <RecentActorsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Contacts</ListItemText>
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  history.push("/documents");
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText>Documents</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  history.push("/settings");
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
              </MenuItem>
            </div>
          )}
          {isOffline && (
            <div>
              {hasAnyEventRole() && (
                <MenuItem
                  onClick={() => {
                    history.push("/events");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <EventOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Events</ListItemText>
                </MenuItem>
              )}
              {hasAnyMonitoringRole() && (
                <MenuItem
                  onClick={() => {
                    history.push("/monitoring");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <GasMeterOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Monitoring</ListItemText>
                </MenuItem>
              )}
            </div>
          )}
        </Menu>
      </>
    );
  };

  const getLeftNavOptions = () => {
    return (
      <>
        {" "}
        <LeftContainer className="nav-options">
          {!isOffline && (
            <>
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                {" "}
                <NavbarSelection>Dashboard</NavbarSelection>
              </Link>

              <Link to="/map" style={{ textDecoration: "none" }}>
                {" "}
                <NavbarSelection>Map</NavbarSelection>
              </Link>
              {organization.featureFlags.showAiFeatures && (
                <Link to="/ai" style={{ textDecoration: "none" }}>
                  <NavbarSelection>Lynx AI</NavbarSelection>
                </Link>
              )}
            </>
          )}
          {isOffline && hasAnyEventRole() && (
            <Link to="/events" style={{ textDecoration: "none" }}>
              {" "}
              <NavbarSelection>Events</NavbarSelection>
            </Link>
          )}
        </LeftContainer>
        {isOffline && (
          <LeftContainer>
            <NavbarSelection style={{ cursor: "default", color: "red" }}>
              Offline Mode
            </NavbarSelection>
          </LeftContainer>
        )}
      </>
    );
  };
  return props.auth.isAuthenticated ? (
    <NavbarTop className="d-print-none">
      <LynxDialog
        title={
          showSyncLoading
            ? "Events syncing. Do not close window."
            : "Sync Events?"
        }
        description={
          showSyncLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            "Any unsynced events will be uploaded. It may take 1-2 minutes for the events to appear in the event list."
          )
        }
        open={showSyncDialog}
        handleClose={
          showSyncLoading
            ? null
            : () => {
                setShowSyncDialog(false);
              }
        }
        handleConfirm={showSyncLoading ? null : handleConfirmSync}
      />

      {showSupport && (
        <LynxDialog
          title={"Contact Support"}
          description={
            <div>
              Please email{" "}
              <a
                href={`mailto:support@lynxglobalintelligence.com?subject=Lynx Support Request`}
              >
                support@lynxglobalintelligence.com
              </a>{" "}
              with a description of your issue or request.
            </div>
          }
          open={showSupport}
          handleClose={() => {
            setShowSupport(false);
          }}
          handleConfirm={() => setShowSupport(false)}
        />
      )}
      {showExpired && (
        <LynxDialog
          title={"Contact Lynx to Continue"}
          description={
            <div>
              Your subscription is expired. Please contact Lynx at
              sales@lynxglobalintelligence.com to renew.
            </div>
          }
          open={showExpired}
          handleConfirm={() => {
            doLogout();
          }}
        />
      )}

      <LogoDiv>
        <LogoContainer
          src={props.isMapModule ? LynxLogoBW : LynxLogo}
        ></LogoContainer>
      </LogoDiv>

      {getMobileMenu()}
      {getUserAccountMenu()}
      {getHelpMenu()}

      {getOfflineSyncOptions()}

      {getLeftNavOptions()}
      {width > 900 && !isOffline && getTour()}
    </NavbarTop>
  ) : (
    <NavbarTop>
      <LogoDiv>
        <LogoContainer src={LynxLogo}></LogoContainer>
      </LogoDiv>
      <LeftContainer>
        {location.pathname.includes("/display") && (
          <NavbarSelection>Powered by Lynx</NavbarSelection>
        )}
      </LeftContainer>
      {props.auth.requiresOrganizationSelection ? (
        <RightContainer>
          <NavbarSelection onClick={doLogout}>Sign out</NavbarSelection>
        </RightContainer>
      ) : (
        <RightContainer>
          <Link to="/authorize" style={{ textDecoration: "none" }}>
            <NavbarSelection>Sign in</NavbarSelection>
          </Link>
        </RightContainer>
      )}
    </NavbarTop>
  );
}

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  swithOrganizationUser: (id) => dispatch(swithOrganizationUser(id)),
});

const mapStateToProps = (state) => ({
  auth: state.auth,
  router: state.router,
  account: state.account,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
