import ClearIcon from "@mui/icons-material/Clear";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { Grid as MuiGrid } from "@mui/material";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import _ from "lodash";
import { roleMatch } from "../../actions/auth";
import { UserRoles } from "../../types/enums";
import { CustomExportButton } from "./custom-export-button";
import "./LynxDataGrid.css";
import { useSelector } from "react-redux";
export function CustomToolbar(props) {
  var organization = useSelector((state) => state.organization);
  return (
    <GridToolbarContainer>
      <MuiGrid container item xs>
        <GridToolbarColumnsButton />
        <Tooltip title="Reset columns">
          <Button size="small" onClick={props.handleShowResetColumnDialog}>
            <RestartAltIcon fontSize="small" className="mr-1" />
            Reset Columns
          </Button>
        </Tooltip>
        <span className="filter-grid-button">
          <GridToolbarFilterButton />
        </span>

        {!props.isOffline &&
          props.enableSaveFilters &&
          !_.isEmpty(props.filterModel.items) && (
            <Tooltip title="Save filter">
              <Button size="small" onClick={props.handleSaveFilter}>
                <SaveIcon fontSize="small" className="mr-1" />
                Save Filter
              </Button>
            </Tooltip>
          )}
        {!_.isEmpty(props.filterModel.items) && (
          <Tooltip title="Clear filter">
            <Button size="small" onClick={props.handleClearFilter}>
              <ClearIcon fontSize="small" className="mr-1" />
              Clear Filter
            </Button>
          </Tooltip>
        )}

        {!props.isOffline && props.enableSaveFilters && (
          <Tooltip title="Saved filters">
            <Button size="small" onClick={props.handleLoadFilter}>
              <Badge
                color="primary"
                invisible={_.isEmpty(props.selectedFilter)}
                variant="dot"
              >
                <FolderSpecialIcon fontSize="small" className="mr-1" />
              </Badge>
              Saved Filters
            </Button>
          </Tooltip>
        )}
        <GridToolbarDensitySelector />
        {!props.isOffline && (
          <CustomExportButton
            handleExportAsXLSX={props.handleExportAsXLSX}
            handleExportAttachments={props.handleExportAttachments}
            entityName={props.entityName}
          />
        )}

        {!props.isOffline && props.enableReports && (
          <Tooltip title="Reports">
            <Button size="small" onClick={props.handleReportsClick}>
              <DescriptionOutlinedIcon fontSize="small" className="mr-1" />
              Reports
            </Button>
          </Tooltip>
        )}

        {!props.isOffline &&
          props.hasAiQuery &&
          organization.featureFlags.showAiFeatures && (
            <Tooltip title="AI Assistant">
              <Button size="small" onClick={props.handleAiQueryClick}>
                <SmartButtonIcon fontSize="small" className="mr-1" />
                AI Assistant
              </Button>
            </Tooltip>
          )}
        {!props.isOffline &&
          props.bulkUpdateTrigger &&
          roleMatch([
            UserRoles.Admin,
            UserRoles.EventsAdministrator,
            UserRoles.MonitoringAdministrator,
          ]) && (
            <Tooltip title="Bulk Update">
              <Button size="small" onClick={props.handleBulkUpdate}>
                <SyncAltIcon fontSize="small" className="mr-1" />
                Bulk Update
              </Button>
            </Tooltip>
          )}
        {props.getQueryParamDisplay && <props.getQueryParamDisplay />}
      </MuiGrid>
    </GridToolbarContainer>
  );
}
