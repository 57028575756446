export const eventGridTourSteps = [
  {
    disableBeacon: true,
    target: ".filter-grid-button",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Create and Save Filters",
    content:
      "Filters are used to quickly view the data you need, when you need it. Filters are also used to create widgets for the Dashboard. Use the Filters button on any grid to create custom filters. Filters can be saved and applied quickly using the Saved Filters button.",
  },
  {
    target: ".export-grid-button",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Export Your Data",
    content:
      "Use the export button to export the current grid view in .csv format.",
  },
  {
    target: ".event-status-header",
    placement: "bottom",
    title: "The Event Workflow Status",
    content:
      "Stay on top of your Events workflow with the Event Status. The event status is automatically set by the platform and identifies the workflow status of the event. The status can be Complete, Incomplete, Overdue, Pending Review, or Pending Actions.",
  },
  {
    target: ".event-number-header",
    placement: "bottom",
    title: "The Event Number",
    content:
      "Each Event is assigned a unique Event Number. Clicking on the Event Number will open the Events Details page.",
  },
  {
    target: ".grid-add-button",
    content:
      "Let’s go ahead and add your first event. Click on the Add Event button on the top right of your page. Clicking this button will open the Add Event form. Try selecting various event categories to see how each event form is configured.",
    placementBeacon: "left",
    placement: "left",
    title: "Add an Event!",
  },
];
