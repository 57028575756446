import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import { Grid, Card, Form } from "components/lynx-components";
import _ from "lodash";

import { getUnusedParameters } from "../../../services/parameters";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";

import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LynxDataGrid } from "./../../data-grid/lynx-data-grid";

import { SearchableSelect } from "./../../form-controls/searchable-select";
import { LynxDialog } from "./../../lynx-dialog";
import { validationService } from "./../../../services/validation";
import { monitoringVarianceLimitColumns } from "./monitoring-variance-limit-columns";
import { EntityTypes, UserRoles } from "../../../types/enums";
import { getMonitoringLocation } from "../../../services/monitoring-location-service";
import useAlert from "hooks/useAlert";
import { roleMatch } from "actions/auth";
import {
  useCreateMonitoringVarianceLimitMutation,
  useDeleteMonitoringVarianceLimitMutation,
  useGetMonitoringVarianceLimitsQuery,
  useUpdateMonitoringVarianceLimitMutation,
} from "services/rtkApi/endpoints/monitoringVariance";
import { useHistory, useParams } from "react-router-dom";
export function MonitoringVariance(props) {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [limitFormState, setLimitFormState] = useState({});
  const [limitToDelete, setLimitToDelete] = useState({});
  const [parameters, setParameters] = useState([]);
  const [columns, setColumns] = useState([]);
  const [createTrigger] = useCreateMonitoringVarianceLimitMutation();
  const [updateTrigger] = useUpdateMonitoringVarianceLimitMutation();
  const [deleteTrigger] = useDeleteMonitoringVarianceLimitMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [monitoringLocation, setMonitoringLocation] = useState({});
  const params = useParams();
  const history = useHistory();
  const initialLimitFormState = {
    id: 0,
    parameterId: "",
    parameterIdError: "",
    varianceLimit: "",
    varianceLimitError: "",
    monitoringLocationId: params.monLocId,
  };
  const { showAlert } = useAlert();
  useEffect(() => {
    loadParameters();
    getMonitoringLocation(params.monLocId).then((res) => {
      setMonitoringLocation(res.data);
    });
  }, []);

  useEffect(() => {
    let newColumns = [...monitoringVarianceLimitColumns];
    let colIndex = monitoringVarianceLimitColumns.findIndex(
      (x) => x.field == "edit"
    );
    if (roleMatch([UserRoles.MonitoringViewer])) {
      newColumns.splice(colIndex, 1);
    } else {
      newColumns[colIndex].renderCell = (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLimit(params.row);
                }}
                aria-label="Notifications"
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {roleMatch([UserRoles.Administrator]) && (
              <Tooltip title="Remove" className="ml-1">
                <IconButton
                  onClick={() => {
                    handleDeleteLimit(params.row);
                  }}
                  aria-label="Remove"
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      };
    }

    setColumns(newColumns);
  }, [monitoringVarianceLimitColumns]);

  const loadParameters = () => {
    getUnusedParameters(
      EntityTypes.MonitoringVarianceLimit,
      params.monLocId
    )
      .then((res) => {
        setParameters(res.data);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };
  const handleNavigateTo = (e, action) => {
    e.preventDefault();
    if (action == "monitoring") {
      history.push("/monitoring?view=locations");
    }
  };

  const handleDeleteLimit = (result) => {
    setLimitToDelete(result);
    setShowDelete(true);
  };
  const handleEditLimit = (limit) => {
    let newLimitFormState = {
      id: limit.id,
      parameterId: limit.parameterId,
      parameterIdError: "",
      varianceLimit: limit.varianceLimit,
      varianceLimitError: "",
    };
    setLimitFormState(newLimitFormState);
    setShowEdit(true);
  };
  const handleAddClick = () => {
    setLimitFormState(initialLimitFormState);
    setShowEdit(true);
  };
  const handleParameterChange = (e) => {
    let existingState = { ...limitFormState };
    setLimitFormState({
      ...existingState,
      parameterId: e.id ?? "",
    });
  };
  const handleLimitInputChange = (e) => {
    let newState = { ...limitFormState };
    let name = e.target.name;
    let value = e.target.value;
    _.set(newState, name, value);
    setLimitFormState(newState);
  };
  const handleSaveLimit = () => {
    if (!validateLimitDataForSave()) {
      return;
    }
    setIsLoading(true);
    let formToSave = { ...limitFormState };
    formToSave = validationService.unsetErrors(
      formToSave,
      "parameterIdError",
      "varianceLimitError"
    );
    if (formToSave.id > 0) {
      updateTrigger({ id: formToSave.id, dto: formToSave }).then((res) => {
        showAlert("success", "Limit saved.");
        setIsLoading(false);
        setShowEdit(false);
        setLimitFormState(initialLimitFormState);
        loadParameters();
      });
    } else {
      createTrigger(formToSave).then((res) => {
        showAlert("success", "Limit saved.");
        setIsLoading(false);
        setShowEdit(false);
        setLimitFormState(initialLimitFormState);
        loadParameters();
      });
    }
  };

  const validateLimitDataForSave = () => {
    let newState = { ...limitFormState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "parameterId",
      "parameterIdError",
      "Parameter"
    );

    validationService.validateRequiredField(
      newState,
      "varianceLimit",
      "varianceLimitError",
      "Allowance"
    );

    isFormValid = !validationService.hasError(
      newState,
      "parameterIdError",
      "varianceLimitError"
    );
    if (!isFormValid) {
      setLimitFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleLimitRemove = () => {
    deleteTrigger(limitToDelete.id).then((res) => {
      showAlert("success", "Allowance deleted.");
      setShowDelete(false);
      setIsLoading(false);
      loadParameters();
    });
  };

  const limitForm = (
    <>
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Form.Group label="Parameter" isRequired>
                <SearchableSelect
                  name={`parameterId`}
                  options={parameters}
                  labelField="name"
                  idField="id"
                  value={limitFormState.parameterId}
                  error={limitFormState.parameterIdError != ""}
                  placeholder={"Search or choose from list"}
                  onChange={handleParameterChange}
                />
              </Form.Group>
            </Grid.Col>

            <Grid.Col md={6} width={12}>
              <Form.Group label="Allowance" isRequired>
                <Form.Input
                  type="number"
                  onChange={handleLimitInputChange}
                  name={`varianceLimit`}
                  error={limitFormState.varianceLimitError}
                  value={limitFormState.varianceLimit}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0">
          <Grid.Col lg={12} width={12} className="">
            <div className="d-flex">
              <Typography
                variant="h3"
                className="form-header-text"
                component="div"
              >
                Edit Monitoring Variance Allowances - ({monitoringLocation.name}
                )
              </Typography>
            </div>
          </Grid.Col>{" "}
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "monitoring")}
              >
                Monitoring
              </Link>
              <Typography color="text.primary">
                Edit Monitoring Variance Allowances - ({monitoringLocation.name}
                )
              </Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Container className="mt-2" maxWidth="xl">
          <Form className="card ">
            <Card.Body className="p-0">
              <Accordion defaultExpanded className="w-100">
                <AccordionSummary
                  aria-controls="details-content"
                  id="details-header"
                >
                  <Typography>Allowances</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!roleMatch([UserRoles.MonitoringViewer]) && (
                    <div className="w-100 d-flex">
                      <Button
                        className="ml-auto  mb-2 mr-1"
                        variant="contained"
                        onClick={handleAddClick}
                      >
                        Add
                      </Button>
                    </div>
                  )}

                  <div style={{ height: "100%" }} className="mt-1">
                    <LynxDataGrid
                      useQuery={useGetMonitoringVarianceLimitsQuery}
                      columns={columns}
                      onlyShowTable
                      autoHeight
                      entityType={EntityTypes.MonitoringVarianceLimit}
                      useQueryParams={{
                        monitoringLocationId: params.monLocId,
                      }}
                      localStorageName={`monitoringVarianceLimits_${params.monLocId}`}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Form>
        </Container>
      </Grid.Row>
      {showEdit && !_.isEmpty(limitFormState) && (
        <LynxDialog
          dividers
          maxWidth={"md"}
          open={showEdit}
          title={limitFormState.id > 0 ? `Edit Allowance` : "Add Allowance"}
          fullWidth
          dialogContent={limitForm}
          handleClose={() => {
            setShowEdit(false);
            setLimitFormState({});
          }}
          handleSave={handleSaveLimit}
        />
      )}
      {showDelete && (
        <LynxDialog
          open={showDelete}
          handleClose={() => {
            setShowDelete(false);
            setLimitToDelete({});
          }}
          handleDelete={() => {
            setIsLoading(true);
            handleLimitRemove();
          }}
          title={`Remove Limit?`}
          description={
            "Are you sure you want to remove this limit from the monitoring location?"
          }
        />
      )}
    </Grid>
  );
}
