import React, { ReactNode, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import InfoIcon from "@mui/icons-material/Info";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { saveAs } from "file-saver";
import _ from "lodash";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import { dateUtil } from "../services/date-util";
import Box from "@mui/material/Box";
import { validateFileSize } from "../services/attachments";
import { LynxDialog } from "./lynx-dialog";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { LynxTextArea } from "./form-controls/lynx-form-controls";
import { downloadImportedAttachment } from "services/import";
import useAlert from "hooks/useAlert";
import { roleMatch } from "actions/auth";
import { EntityTypes, UserRoles } from "types/enums";
import { EntityAttachmentDto } from "types";
import {
  useAddEntityAttachmentsMutation,
  useDeleteEntityAttachmentMutation,
  useGetEntityAttachmentsQuery,
  useLazyDownloadAttachmentQuery,
  useUpdateAttachmentDescriptionMutation,
} from "services/rtkApi/endpoints/attachments";
import lynxColors from "modules/lynxColors";

interface AttachmentViewerProps {
  isExistingEntity: boolean;
  entityType?: string;
  entityId?: number;
  handleSetUploadedFiles?: (files: File[]) => void;
  onUpload?: () => void;
  isLocked?: boolean;
  cardClass?: string;
  title?: string | ReactNode;
  cols?: number;
  rowHeight?: number;
  importAttachments?: any[];
  disableDelete?: boolean;
}
export interface NewFile extends EntityAttachmentDto {
  name?: string;
  url?: string;
  contentType?: string;
  lastModified?: number;

  importHistoryId?: number;
  isImport?: boolean;
  uploaderUserFullName?: string;
  importDateTimeUtc?: string;
}
export function AttachmentViewer(props: AttachmentViewerProps) {
  const [files, setFiles] = useState<NewFile[]>([]);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<NewFile>({});

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [attachmentsUploading, setAttachmentsUploading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [showDownloading, setShowDownloading] = useState(false);
  const dimensions = useWindowDimensions();
  const [attachmentDescription, setAttachmentDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { showAlert } = useAlert();

  const [addEntityAttachmentsTrigger] = useAddEntityAttachmentsMutation();
  const [deleteEntityAttachmentTrigger] = useDeleteEntityAttachmentMutation();
  const [downloadAttachmentTrigger] = useLazyDownloadAttachmentQuery();
  const [downloadThumbnailTrigger] = useLazyDownloadAttachmentQuery();
  const [updateAttachmentDescriptionTrigger] =
    useUpdateAttachmentDescriptionMutation();
  const { data: entityAttachments, isFetching } = useGetEntityAttachmentsQuery(
    { entityType: props.entityType, entityId: props.entityId },
    { skip: !props.isExistingEntity || !props.entityType || !props.entityId }
  );

  useEffect(() => {
    if (!_.isEmpty(uploadedFiles)) {
      let newFiles: NewFile[] = [];
      uploadedFiles.forEach((file) => {
        const fileUrl = URL.createObjectURL(file);

        newFiles.push({
          name: file.name,
          url: fileUrl,
          contentType: file.type,
          lastModified: file.lastModified,
        });
        return () => URL.revokeObjectURL(fileUrl);
      });
      setFiles([...newFiles]);
    }
  }, [uploadedFiles]);
  useEffect(() => {
    if (!props.isExistingEntity) {
      setIsLoading(false);
    }
  }, [props.isExistingEntity]);
  useEffect(() => {
    if (!isFetching) {
      if (!_.isEmpty(entityAttachments)) {
        prepAndSetExistingFiles(entityAttachments as EntityAttachmentDto[]);
      } else {
        setIsLoading(false);
      }
    }
  }, [isFetching]);
  const handleUploadClick = (event: any) => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleDownloadImportFile = (id: number, fileName: string) => {
    downloadImportedAttachment(id).then((res) => {
      saveAs(res.data, fileName);
    });
  };

  const handleFileChange = (event: any) => {
    const selectedFiles = event.target.files;
    if (validateFileSize([...uploadedFiles, ...selectedFiles])) {
      if (!props.isExistingEntity) {
        var newFiles = [...uploadedFiles, ...selectedFiles];
        setUploadedFiles(newFiles);
        if (props.handleSetUploadedFiles) {
          props.handleSetUploadedFiles(newFiles);
        }
      } else {
        setAttachmentsUploading(true);
        addEntityAttachmentsTrigger({
          entityType: props.entityType,
          entityId: props.entityId,
          attachments: selectedFiles,
        })
          .then((res: any) => {
            props.onUpload && props.onUpload();
            setAttachmentsUploading(false);
          })
          .catch(() => {
            showAlert("error", "Error uploading attachments.");
            setAttachmentsUploading(false);
          });
      }
    } else {
      setShowFileSizeError(true);
    }
  };

  const prepAndSetExistingFiles = async (
    entityAttachments: EntityAttachmentDto[]
  ) => {
    setIsLoading(true);
    const newFiles = [...entityAttachments];
    const filePromises = newFiles
      .filter(
        (x) =>
          !files.map((a) => a.entityAttachmentId).includes(x.entityAttachmentId)
      )
      .map((file) => {
        if (file.thumbnailPath) {
          return downloadAttachmentTrigger(file.entityAttachmentId).then(
            (res: any) => {
              if (res.data) {
                const fileUrl = URL.createObjectURL(res.data);
                setFiles((existing) => [
                  {
                    name: file.fileName,
                    url: fileUrl,
                    contentType: file.contentType,
                    entityAttachmentId: file.entityAttachmentId,
                    createdByUserFullName: file.createdByUserFullName,
                    createdDateTimeUtc: file.createdDateTimeUtc,
                    description: file.description,
                  },
                  ...existing,
                ]);
                return () => URL.revokeObjectURL(fileUrl);
              }
              if (res.error) {
                setFiles((existing) => [
                  {
                    name: file.fileName,
                    contentType: file.contentType,
                    entityAttachmentId: file.entityAttachmentId,
                    createdByUserFullName: file.createdByUserFullName,
                    createdDateTimeUtc: file.createdDateTimeUtc,
                    description: file.description,
                  },
                  ...existing,
                ]);
              }
            }
          );
        } else {
          return Promise.resolve(
            setFiles((existing) => [
              {
                name: file.fileName,
                contentType: file.contentType,
                entityAttachmentId: file.entityAttachmentId,
                createdByUserFullName: file.createdByUserFullName,
                createdDateTimeUtc: file.createdDateTimeUtc,
                description: file.description,
              },
              ...existing,
            ])
          );
        }
      });

    // Wait for all file promises to complete
    await Promise.all(filePromises);

    // Set loading to false only after all files are processed
    setIsLoading(false);
  };

  const handleDownloadFile = (id: number, fileName: string) => {
    setShowDownloading(true);
    downloadAttachmentTrigger(id)
      .then((res: any) => {
        if (res.data) {
          saveAs(res.data, fileName);
        }
      })
      .catch(async (err) => {
        var errorString = JSON.parse(await err.response.data.text());
        showAlert("error", errorString);
      })
      .finally(() => {
        setShowDownloading(false);
      });
  };
  const handleInputClick = (event: any) => {
    event.target.value = "";
  };

  const handleViewAttachment = (file: NewFile) => {
    setSelectedFile(file);
    setShowAttachmentDialog(true);
    if (file.description) {
      setAttachmentDescription(file.description);
    }
  };

  const handleDeleteAttachment = () => {
    if (props.isExistingEntity) {
      deleteEntityAttachmentTrigger(selectedFile.entityAttachmentId).then(
        (res: any) => {
          let newFiles = files.filter(
            (x) => x.entityAttachmentId != selectedFile.entityAttachmentId
          );
          setFiles(newFiles);
        }
      );
    } else {
      let newFiles = files.filter(
        (x) =>
          x.name != selectedFile.name &&
          x.lastModified != selectedFile.lastModified
      );

      setFiles(newFiles);
      if (props.handleSetUploadedFiles) {
        props.handleSetUploadedFiles(
          uploadedFiles.filter(
            (x) =>
              x.name != selectedFile.name &&
              x.lastModified != selectedFile.lastModified
          )
        );
      }
    }
    showAlert("success", "Attachment deleted.");
    setShowDelete(false);
    setShowAttachmentDialog(false);
  };

  const handleSaveDescription = () => {
    const dto = {
      description: attachmentDescription,
    };
    updateAttachmentDescriptionTrigger({
      id: selectedFile.entityAttachmentId,
      dto: dto,
    }).then((res) => {
      showAlert("success", "Attachment description updated.");
      const newThumbnails = [...files];
      const index = newThumbnails.findIndex(
        (f) => f.entityAttachmentId == selectedFile.entityAttachmentId
      );
      if (index != -1) {
        newThumbnails[index].description = attachmentDescription;
        setFiles(newThumbnails);
      }
    });
  };

  const userRoleCanUploadAndSave =
    props.entityType == EntityTypes.Event
      ? roleMatch([
          UserRoles.EventsAdministrator,
          UserRoles.EventsEditor,
          UserRoles.EventsContributor,
        ])
      : true;

  const userRoleCanDelete = EntityTypes.Event
    ? roleMatch([UserRoles.EventsAdministrator])
    : true;

  return (
    <Card className={props.cardClass ?? ""}>
      <Card.Header className="justify-content-between">
        <Card.Title>{props.title ?? "Attachments"}</Card.Title>
        <input
          type="file"
          multiple
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: "none" }}
          onClick={handleInputClick}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
    text/plain, image/*, text/html, video/*, audio/*, .pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
    .doc, .docx"
        />
        {userRoleCanUploadAndSave && (
          <Button disabled={props.isLocked} onClick={handleUploadClick}>
            Upload
          </Button>
        )}
      </Card.Header>
      <Card.Body className={`${files.length <= 0 ? "" : "p-0"}`}>
        <Dimmer active={isLoading} loader>
          {files.length <= 0 && !isLoading && (
            <Grid.Row>
              <Grid.Col md={6} width={12}>
                <div>No attachments</div>
              </Grid.Col>
            </Grid.Row>
          )}
          <ImageList
            cols={dimensions.isMobile ? 2 : props.cols ?? 3}
            gap={8}
            rowHeight={props.rowHeight ?? 150}
          >
            {props.importAttachments &&
              props.importAttachments.map((file, i) => (
                <ImageListItem
                  key={"system" + _.toString(i)}
                  sx={{ border: `1px solid ${lynxColors.gray}` }}
                >
                  <div className=" h-100 d-flex align-items-center justify-content-center">
                    <InsertDriveFileIcon
                      color="action"
                      className="mb-5"
                      sx={{ fontSize: 150 }}
                    />
                  </div>

                  <ImageListItemBar
                    title={file.fileName}
                    key={"systemItem" + _.toString(i)}
                    actionIcon={
                      <IconButton
                        key={"systemIcon" + _.toString(i)}
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${file.fileName}`}
                        onClick={() => handleViewAttachment(file)}
                      >
                        <InfoIcon key={"systemInfo" + _.toString(i)} />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            {files.map((file, i) => (
              <>
                {file.contentType?.includes("image") ? (
                  <ImageListItem
                    sx={{ border: `1px solid ${lynxColors.gray}` }}
                    key={"list" + _.toString(i)}
                  >
                    <img
                      alt={file.name}
                      src={file.url}
                      loading="lazy"
                      key={"img" + _.toString(i)}
                      style={{
                        objectFit: "contain",
                        height: 100,
                        minWidth: 150,
                      }}
                    />

                    <ImageListItemBar
                      title={file.name}
                      key={
                        "item" +
                        _.toString(file.entityAttachmentId || file.name)
                      }
                      actionIcon={
                        <IconButton
                          key={
                            "icon" +
                            _.toString(file.entityAttachmentId || file.name)
                          }
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${file.fileName}`}
                          onClick={() => handleViewAttachment(file)}
                        >
                          <InfoIcon
                            key={"info" + _.toString(file.entityAttachmentId)}
                          />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ) : (
                  <ImageListItem
                    key={"list" + _.toString(i)}
                    className="w-100"
                    sx={{ border: `1px solid ${lynxColors.gray}` }}
                  >
                    <div className=" h-100 w-100 d-flex align-items-center justify-content-center">
                      {getFileIcon(file)}
                    </div>

                    <ImageListItemBar
                      title={file.name}
                      key={
                        "item" +
                        _.toString(file.entityAttachmentId || file.name)
                      }
                      actionIcon={
                        <IconButton
                          key={
                            "icon" +
                            _.toString(file.entityAttachmentId || file.name)
                          }
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${file.fileName}`}
                          onClick={() => handleViewAttachment(file)}
                        >
                          <InfoIcon
                            key={"info" + _.toString(file.entityAttachmentId)}
                          />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                )}
              </>
            ))}
            {showAttachmentDialog && !_.isEmpty(selectedFile) && (
              <LynxDialog
                open={showAttachmentDialog && !_.isEmpty(selectedFile)}
                handleClose={() => setShowAttachmentDialog(false)}
                handleDownload={() => {
                  if (selectedFile.isImport) {
                    handleDownloadImportFile(
                      selectedFile.importHistoryId as number,
                      selectedFile.fileName as string
                    );
                  } else {
                    handleDownloadFile(
                      selectedFile.entityAttachmentId as number,
                      selectedFile.name as string
                    );
                  }
                }}
                handleDelete={
                  selectedFile.isImport || !userRoleCanDelete
                    ? null
                    : () => setShowDelete(true)
                }
                disableDelete={props.disableDelete || props.isLocked}
                title={selectedFile.name}
                isCloseInHeader
                handleSave={
                  !props.isLocked &&
                  selectedFile.entityAttachmentId &&
                  userRoleCanUploadAndSave &&
                  handleSaveDescription
                }
                description={
                  <>
                    <div className="p-3">
                      {selectedFile.contentType &&
                      selectedFile.contentType.includes("image") &&
                      selectedFile.url ? (
                        <img
                          src={selectedFile.url}
                          alt={
                            "img" +
                            _.toString(
                              selectedFile.entityAttachmentId ||
                                selectedFile.name
                            )
                          }
                          loading="lazy"
                          key={
                            "img" +
                            _.toString(
                              selectedFile.entityAttachmentId ||
                                selectedFile.name
                            )
                          }
                        />
                      ) : (
                        getFileIcon(selectedFile)
                      )}
                    </div>
                    {(selectedFile.entityAttachmentId ||
                      selectedFile.isImport) && (
                      <Box>
                        <Form.Group label="Description">
                          <LynxTextArea
                            name="contactDetails"
                            onChange={(e: any) =>
                              setAttachmentDescription(e.target.value)
                            }
                            value={
                              selectedFile.isImport
                                ? `File imported on ${dateUtil.convertDateTimeToLocal(
                                    selectedFile.importDateTimeUtc
                                  )} by ${selectedFile.uploaderUserFullName}.`
                                : attachmentDescription
                            }
                            disabled={props.isLocked || selectedFile.isImport}
                          ></LynxTextArea>
                        </Form.Group>
                      </Box>
                    )}

                    {selectedFile.createdByUserFullName && (
                      <>
                        <Typography variant="subtitle2">
                          Uploaded By: {selectedFile.createdByUserFullName}
                        </Typography>
                        <Typography variant="subtitle2">
                          {dateUtil.convertDateTimeToLocal(
                            selectedFile.createdDateTimeUtc
                          )}
                        </Typography>
                      </>
                    )}
                  </>
                }
              />
            )}
          </ImageList>
        </Dimmer>
      </Card.Body>
      <LynxDialog
        open={showFileSizeError}
        handleClose={() => setShowFileSizeError(false)}
        title={`File size exceeded`}
        description={
          "Attachments are over the required total size limit of 50MB."
        }
        handleConfirm={() => setShowFileSizeError(false)}
      />
      <LynxDialog
        open={attachmentsUploading}
        title={`Uploading attachments. Do not close the window.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />

      <LynxDialog
        open={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={handleDeleteAttachment}
        title={`Delete Attachment?`}
        description={"The action can not be undone."}
      />
      <LynxDialog
        open={showDownloading}
        title={`Downloading attachment.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />
    </Card>
  );
}

const getFileIcon = (file: NewFile) => {
  if (file.contentType && file.contentType.includes("video")) {
    return <VideoFileIcon color="action" sx={{ fontSize: 150 }} />;
  }
  if (file.contentType && file.contentType.includes("audio")) {
    return <AudioFileIcon color="action" sx={{ fontSize: 150 }} />;
  }
  return (
    <InsertDriveFileIcon
      color="action"
      className="mb-5"
      sx={{ fontSize: 150 }}
    />
  );
};
