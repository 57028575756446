import { Redirect, useLocation } from "react-router-dom";
import { loggedIn, roleMatch } from "../actions/auth";
import queryString from "query-string";
import PageNotFound from "./PageNotFound";
import { UserRoles } from "types/enums";
import { useState, useEffect } from "react";
import { hasToken } from "actions/auth";
import { isTokenExpired } from "./../actions/auth";
import { refreshAccessToken } from "services/rtkApi/apiService";

export function RequirePortalAuth({ children, roles }) {
  var location = useLocation();
  const redirectPath = { redirect: location.pathname };
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isPortalUser = loggedIn() ? roleMatch([UserRoles.PortalUser]) : false;
  const isPortalAdmin = loggedIn() ? roleMatch([UserRoles.PortalAdmin]) : false;
  useEffect(() => {
    const checkAuth = async () => {
      if (hasToken() && isTokenExpired()) {
        try {
          await refreshAccessToken(); // Wait for the token to refresh
        } catch (error) {
          console.error("Failed to refresh token:", error);
          setIsAuthenticated(false);
          setIsLoading(false);
          return;
        }
      }
      setIsAuthenticated(loggedIn());
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <></>;
  }
  if (
    isAuthenticated &&
    (isPortalUser || isPortalAdmin) &&
    roles === undefined
  ) {
    return children;
  }

  if (isAuthenticated && (isPortalUser || isPortalAdmin) && roleMatch(roles)) {
    return children;
  }
  if (isAuthenticated && (isPortalUser || isPortalAdmin) && !roleMatch(roles)) {
    return <PageNotFound />;
  } else {
    return (
      <Redirect
        to={{
          pathname: "/wyloo",
          state: { from: location },
          search: `?${queryString.stringify(redirectPath)}`,
        }}
      />
    );
  }
}
