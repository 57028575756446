import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Card, Form, Grid } from "components/lynx-components";
import { LocalStorageKeys } from "../../../../types/enums";
import useAlert from "hooks/useAlert";
export function DeviceSettings() {
  const [disableOfflineMode, setDisableOfflineMode] = useState(null);
  const [offlineTimeout, setOfflineTimeout] = useState(null);
  const [offlineTimeoutError, setOfflineTimeoutError] = useState("");
  const { showAlert } = useAlert();

  useEffect(() => {
    var offlineTimeoutLs = localStorage.getItem(
      LocalStorageKeys.OfflineTimeout
    );
    if (offlineTimeoutLs) {
      setOfflineTimeout(offlineTimeoutLs);
    } else {
      setOfflineTimeout(3000);
    }

    var disableOfflineModeLs = localStorage.getItem(
      LocalStorageKeys.DisableOfflineMode
    );
    if (disableOfflineModeLs != null) {
      setDisableOfflineMode(disableOfflineModeLs === "false" ? false : true);
    } else {
      setDisableOfflineMode(false);
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem(
      LocalStorageKeys.DisableOfflineMode,
      disableOfflineMode
    );
    if (disableOfflineMode == true) {
      setOfflineTimeoutError("");
      showAlert("success", "Device settings saved.");
      return;
    }
    if (offlineTimeout && offlineTimeout >= 3000 && offlineTimeout <= 20000) {
      localStorage.setItem(LocalStorageKeys.OfflineTimeout, offlineTimeout);
      setOfflineTimeoutError("");
    } else {
      setOfflineTimeoutError(
        "Please enter a valid number between 3000 and 20000"
      );
      return;
    }
    showAlert("success", "Device settings saved.");
  };
  return (
    <Container className="mt-2" maxWidth="xl">
      <Form className="card ">
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, .03)",
            width: "100%",
            height: "100%",
          }}
        >
          <Card.Header>
            <Typography>Device Settings</Typography>
          </Card.Header>{" "}
        </div>
        <Card.Body className="pt-2 pb-0 pl-0 pr-0">
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              {disableOfflineMode != null && (
                <Form.Group className="" label="Disable offline mode?">
                  <Form.Checkbox
                    label=" "
                    disabled
                    name="appliesToPermit"
                    checked={disableOfflineMode}
                    onChange={(e) => {
                      setDisableOfflineMode(e.target.checked);
                    }}
                  />
                  <Typography>
                    Only recommended for devices that are always connected to
                    the internet.
                  </Typography>
                </Form.Group>
              )}
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              {offlineTimeout != null && (
                <Form.Group isRequired={true} label="Offline Timeout (ms)">
                  <Form.Input
                    min="3000"
                    max="10000"
                    type="number"
                    placeholder="3000"
                    name={"offlineTimeout"}
                    error={offlineTimeoutError}
                    onChange={(e) => {
                      setOfflineTimeout(e.target.value);
                    }}
                    disabled={
                      //disableOfflineMode
                      true
                    }
                    value={offlineTimeout}
                  ></Form.Input>
                </Form.Group>
              )}
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Button
                onClick={handleSave}
                variant="contained"
                className="mb-2"
                disabled
              >
                Save
              </Button>
              <Typography
                variant="caption"
                color="textSecondary"
                className="ml-2 text-red"
              >
                Offline mode not supported.
              </Typography>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Form>
    </Container>
  );
}
