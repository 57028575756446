import decode from "jwt-decode";
import { apiUrl } from "../modules/store";
import { LocalStorageKeys, UserRoles } from "../types/enums";
import { getOnlineStatus } from "hooks/useOnlineStatus";
//API calls should be in actions and not reducers. Reducer is only for chagning state.
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";

const requestLogin = (creds) => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: user.token,
    offlineToken: user.offlineToken,
    organizationUsers: user.organizationUsers,
  };
};

const receiveRefreshToken = () => {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
  };
};

const loginError = (message) => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
};

export const swithOrganizationUser = (organizationUserId) => {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return (dispatch) => {
    dispatch(loginError(null));
    dispatch(requestLogin(null));

    return fetch(
      apiUrl + `/auth/organizationUser/${organizationUserId}`,
      config
    )
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          dispatch(loginError(user.message));
          return Promise.reject(user);
        } else {
          // If login was successful, set the token in local storage
          if (user.token) {
            localStorage.setItem(LocalStorageKeys.AccessToken, user.token);
          }
          if (user.offlineToken) {
            localStorage.setItem(
              LocalStorageKeys.OfflineToken,
              user.offlineToken
            );
          }

          // Dispatch the success action
          dispatch(receiveLogin(user));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const refreshTokenSuccess = () => {
  return (dispatch) => {
    dispatch(receiveRefreshToken());
  };
};

export const loginUser = (creds) => {
  let config = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(creds),
    credentials: "include",
  };

  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(loginError(null));
    dispatch(requestLogin(creds));

    return fetch(apiUrl + "/auth/login", config)
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          dispatch(loginError(user.message));
          return Promise.reject(user);
        } else {
          // If login was successful, set the token in local storage
          if (user.token) {
            localStorage.setItem(LocalStorageKeys.AccessToken, user.token);
          }
          if (user.offlineToken) {
            localStorage.setItem(
              LocalStorageKeys.OfflineToken,
              user.offlineToken
            );
          }
          // Dispatch the success action
          dispatch(receiveLogin(user));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem(LocalStorageKeys.AccessToken);
    localStorage.removeItem(LocalStorageKeys.OfflineToken);
    dispatch(receiveLogout());
  };
}

export function getName() {
  var token = getToken();
  if (!token) return "";
  var decodedToken = decode(token);
  return decodedToken.email;
}

function getToken() {
  const isOnline = getOnlineStatus();
  if (!isOnline) {
    console.warn("User is offline. Using offline token.");
    return localStorage.getItem(LocalStorageKeys.OfflineToken); // Use offline token
  }
  // Retrieves the user token from localStorage
  return localStorage.getItem(LocalStorageKeys.AccessToken);
}

export function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // GEtting token from localstorage
  return !!token && !isTokenExpired(token); // handwaiving here
}

export function hasToken() {
  const token = localStorage.getItem(LocalStorageKeys.AccessToken);
  if (!token) return false;
  return true;
}
export function getUserId() {
  var token = getToken();
  if (!token) return "";
  var decodedToken = decode(token);
  return decodedToken.userId;
}
export function getOrganizationUserId() {
  var token = getToken();
  if (!token) return "";
  var decodedToken = decode(token);
  return decodedToken.nameid;
}
export function isTokenExpired(token) {
  if (!token) {
    token = localStorage.getItem(LocalStorageKeys.AccessToken);
  }
  if (!token) return true;
  try {
    const decoded = decode(token);
    //subtract 30 days from ceiling date if offline mode
    var ceilingDate = Date.now() / 1000;

    //kick people out by invalidating refresh token
    // if (decoded.iat < 1732579701) {
    //   return true;
    // }
    if (decoded.exp < ceilingDate) {
      // Checking if token is expired. N
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
}

export function roleMatch(allowedRoles) {
  let isRoleMatch = false;
  var token = getToken();
  if (!token) return false;
  let userRoles = decode(token).role;
  if (userRoles === undefined) {
    userRoles = ["none"];
  }
  allowedRoles.forEach((element) => {
    if (userRoles.includes(element)) {
      isRoleMatch = true;
    }
  });
  return isRoleMatch;
}

export function hasAnyEventRole() {
  var allowedRoles = [
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
    UserRoles.EventsViewer,
  ];
  let isRoleMatch = false;
  var token = getToken();
  if (!token) return false;
  let userRoles = decode(token).role;
  if (userRoles === undefined) {
    userRoles = ["none"];
  }
  allowedRoles.forEach((element) => {
    if (userRoles.includes(element)) {
      isRoleMatch = true;
    }
  });
  return isRoleMatch;
}

export function hasAnyMonitoringRole() {
  var allowedRoles = [
    UserRoles.MonitoringAdministrator,
    UserRoles.MonitoringEditor,
    UserRoles.MonitoringViewer,
  ];
  let isRoleMatch = false;
  var token = getToken();
  if (!token) return false;
  let userRoles = decode(token).role;
  if (userRoles === undefined) {
    userRoles = ["none"];
  }
  allowedRoles.forEach((element) => {
    if (userRoles.includes(element)) {
      isRoleMatch = true;
    }
  });
  return isRoleMatch;
}
