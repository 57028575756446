import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory, useParams } from "react-router-dom";
import { Dimmer, Grid } from "components/lynx-components";
import Box from "@mui/material/Box";
import {
  downloadImage,
  getPhotoImportHistory,
  skipPhotoForTraining,
} from "../../../services/time-series-service";
import _ from "lodash";
import * as markerjs2 from "markerjs2";
import Button from "@mui/material/Button";
import { sendPhotoForTraining } from "./../../../services/time-series-service";
import lynxColors from "../../../modules/lynxColors";
import { LynxDialog } from "../../lynx-dialog";
import useAlert from "hooks/useAlert";
export const PhotoImportHistoryTraining = (props) => {
  const history = useHistory();
  const [markerAdded, setMarkerAdded] = useState(false);
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [trainingPhotos, setTrainingPhotos] = useState([]);
  const [groundTruth, setGroundTruth] = useState("");
  const [maState, setMaState] = useState({});
  const [photosLoading, setPhotosLoading] = useState(true);
  const imgRef = useRef();
  const { showAlert } = useAlert();
  const { photoImportHistoryId } = useParams();
  useEffect(() => {
    getPhotoImportHistory(photoImportHistoryId).then(
      (res) => {
        if (res.data.photosPendingTrainingJson) {
          var photoArray = JSON.parse(res.data.photosPendingTrainingJson);
          setupPhotos(photoArray);
        }
      }
    );
  }, [photoImportHistoryId]);

  const setupPhotos = async (photoArray) => {
    for (let i = 0; i < photoArray.length; i++) {
      var res = await downloadImage(photoArray[i].FilePath);
      const fileUrl = URL.createObjectURL(res.data);
      photoArray[i].imageUrl = fileUrl;
      setTrainingPhotos((existing) => [...existing, photoArray[i]]);
      if (i == photoArray.length - 1) {
        setPhotosLoading(false);
      }
    }
  };

  const handleNavigateTo = (url) => {
    history.push(url);
  };
  const showMarkerArea = () => {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea

      const markerArea = new markerjs2.MarkerArea(imgRef.current);

      markerArea.availableMarkerTypes = [markerjs2.FrameMarker];
      markerArea.settings.displayMode = "popup";
      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
          setMaState(event.state);
          if (event.state.markers.length > 0) {
            transformMarkers(event.markerArea);
            setMarkerAdded(true);
          } else {
            setMarkerAdded(false);
            setGroundTruth();
          }
        }
      });

      // launch marker.js
      markerArea.show();
      if (!_.isEmpty(maState)) {
        markerArea.restoreState(maState);
      }
    }
  };

  const transformMarkers = (markerArea) => {
    let objectToCreate = {
      "bounding-box": {
        image_size: [
          {
            width: markerArea.imageWidth,
            height: markerArea.imageHeight,
            depth: 3,
          },
        ],
        annotations: [],
      },
      "bounding-box-metadata": {
        objects: [],
        "class-map": {
          0: trainingPhotos[currentPhotoIndex].Label,
        },
        type: "groundtruth/object-detection",
        "human-annotated": "yes",
        "creation-date": "2013-11-18T02:53:27",
        "job-name": "my job",
      },
    };
    markerArea.markers.forEach((marker) => {
      objectToCreate["bounding-box"].annotations.push({
        class_id: 0,
        left: marker.left,
        top: marker.top,
        width: marker.width,
        height: marker.height,
      });
      objectToCreate["bounding-box-metadata"].objects.push({
        confidence: 1,
      });
    });
    setGroundTruth(objectToCreate);
  };

  const handleSubmit = () => {
    sendPhotoForTraining(
      photoImportHistoryId,
      trainingPhotos[currentPhotoIndex].FilePath,
      groundTruth,
      trainingPhotos[currentPhotoIndex].Label
    ).then((res) => {
      setMarkerAdded(false);
      let photos = [...trainingPhotos];
      photos.splice(currentPhotoIndex, 1);
      setTrainingPhotos(photos);
      showAlert("success", "Photo submitted for training.");
      if (_.isEmpty(photos)) {
        handleNavigateTo("/monitoring?view=timeseries");
      } else {
        setPhotosLoading(false);
        setMaState({});
        if (currentPhotoIndex > photos.length - 1) {
          setCurrentPhotoIndex(currentPhotoIndex - 1);
        }
      }
    });
  };
  const handleSkip = () => {
    skipPhotoForTraining(
      photoImportHistoryId,
      trainingPhotos[currentPhotoIndex].FilePath
    ).then((res) => {
      setMarkerAdded(false);
      let photos = [...trainingPhotos];
      photos.splice(currentPhotoIndex, 1);
      setTrainingPhotos(photos);
      showAlert("error", "Photo skipped for training.");
      if (_.isEmpty(photos)) {
        handleNavigateTo("/monitoring?view=timeseries");
      } else {
        setPhotosLoading(false);
        setMaState({});
        if (currentPhotoIndex > photos.length - 1) {
          setCurrentPhotoIndex(currentPhotoIndex - 1);
        }
      }
    });
  };
  return (
    <Dimmer active={photosLoading} loader>
      <Paper>
        <Grid.Row className="ml-0 mr-0">
          <Grid.Col lg={12} width={12} className="">
            <div className="d-flex">
              <Typography
                variant="h3"
                className="event-number event-number-title"
                component="div"
              >
                Wildlife AI Model Training
              </Typography>
            </div>
          </Grid.Col>

          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className="events-link"
                onClick={(e) => handleNavigateTo("/monitoring?view=timeseries")}
              >
                Timeseries
              </Link>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>

      <div className="d-flex align-items-center justify-content-center">
        <Box
          height={50}
          width={900}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          gap={4}
          p={2}
          sx={{
            border: `1px solid ${lynxColors.gray}`,
            backgroundColor: "white",
            marginTop: "0.5rem",
          }}
        >
          <IconButton
            aria-label="back_button"
            className="mr-auto"
            disabled={currentPhotoIndex === 0}
            onClick={() => {
              setCurrentPhotoIndex(currentPhotoIndex - 1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">
            {currentPhotoIndex + 1 + "/" + trainingPhotos.length}
          </Typography>
          <Button variant="outlined" onClick={() => showMarkerArea()}>
            Label Photo -{" "}
            {trainingPhotos[currentPhotoIndex] &&
              trainingPhotos[currentPhotoIndex].Label}
          </Button>
          <Button
            variant="outlined"
            color="error"
            className="ml-2"
            onClick={handleSkip}
          >
            Skip Photo for Training
          </Button>
          <Button
            variant="outlined"
            color="success"
            className="ml-2"
            onClick={handleSubmit}
            disabled={!markerAdded}
          >
            Submit for Training
          </Button>
          <IconButton
            aria-label="back_button"
            className="ml-auto"
            onClick={() => {
              if (markerAdded) {
                setShowSubmitDialog(true);
              } else {
                setCurrentPhotoIndex(currentPhotoIndex + 1);
              }
            }}
            disabled={currentPhotoIndex === trainingPhotos.length - 1}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        {!photosLoading && (
          <img
            ref={imgRef}
            alt=""
            src={trainingPhotos[currentPhotoIndex].imageUrl}
          ></img>
        )}
      </div>
      <LynxDialog
        handleConfirm={() => {
          setCurrentPhotoIndex(currentPhotoIndex + 1);
          setShowSubmitDialog(false);
          setMaState({});
        }}
        handleClose={() => setShowSubmitDialog(false)}
        open={showSubmitDialog}
        title={`Are you sure you want to leave this page? Press "Submit for Training" to submit this photo.`}
      />
    </Dimmer>
  );
};
