import React, { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import queryString from "query-string";

import PageNotFound from "./PageNotFound";
import { UserRoles } from "types/enums";
import { hasToken, isTokenExpired, loggedIn, roleMatch } from "actions/auth";
import { refreshAccessToken } from "services/rtkApi/apiService";

export function RequireAuth({ children, roles }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  const redirectPath = { redirect: location.pathname + location.search };
  useEffect(() => {
    const checkAuth = async () => {
      if (hasToken() && isTokenExpired()) {
        try {
          await refreshAccessToken(); // Wait for the token to refresh
        } catch (error) {
          console.error("Failed to refresh token:", error);
          setIsAuthenticated(false);
          setIsLoading(false);
          return;
        }
      }

      setIsAuthenticated(loggedIn());
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <></>;
  }

  const isPortalUser = loggedIn() ? roleMatch([UserRoles.PortalUser]) : false;

  if (isAuthenticated && !isPortalUser && roles === undefined) {
    return children;
  }

  if (isAuthenticated && !isPortalUser && roleMatch(roles)) {
    return children;
  }

  if (isAuthenticated && !isPortalUser && !roleMatch(roles)) {
    return <PageNotFound />;
  }

  return (
    <Redirect
      to={{
        pathname: isPortalUser ? "/wyloo" : `/authorize`,
        state: { from: location },
        search: isPortalUser ? null : `?${queryString.stringify(redirectPath)}`,
      }}
    />
  );
}
