import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "components/lynx-components";
import CircularProgress from "@mui/material/CircularProgress";
import {
  downloadImage,
  getPhotoImportConfigurationSupportedLabels,
  getPhotoImportHistoryResults,
  submitTimeseriesReview,
} from "../../../services/time-series-service";
import PhotoCarousel from "./photo-carousel";
import Button from "@mui/material/Button";
import { LynxDialog } from "../../lynx-dialog";
import { LocalStorageKeys } from "../../../types/enums";
import useAlert from "hooks/useAlert";
export const PhotoImportHistoryReview = (props) => {
  const history = useHistory();
  const [results, setResults] = useState([]);
  const [supportedLabels, setSupportedLabels] = useState([]);
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [photosLoading, setPhotosLoading] = useState(true);
  const [photoCount, setPhotoCount] = useState(0);
  const [downloadCount, setDownloadCount] = useState(0);
  const [fullSizeImage, setFullSizeImage] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { showAlert } = useAlert();
  const { photoImportHistoryId } = useParams();
  const downloadImages = async (values, objToAdd) => {
    const responses = [];
    for (let i = 0; i < values.length; i++) {
      var res = await downloadImage(values[i].filePath);
      const fileUrl = URL.createObjectURL(res.data);
      objToAdd.values = [
        ...objToAdd.values,
        {
          ...values[i],
          imageUrl: fileUrl,
        },
      ];
      setDownloadCount((existingCount) => existingCount + 1);
      setResults((existingResults) => {
        var existingIndex = existingResults.findIndex(
          (x) => x.dateTime === objToAdd.dateTime
        );
        if (existingIndex > -1) {
          existingResults[existingIndex] = objToAdd;
          return [...existingResults];
        } else {
          return [...existingResults, objToAdd];
        }
      });
    }
    return responses;
  };

  useEffect(() => {
    if (downloadCount > 0 && photoCount === downloadCount) {
      setPhotosLoading(false);
    }
  }, [photoCount, downloadCount]);
  useEffect(() => {
    if (photoImportHistoryId) {
      getPhotoImportConfigurationSupportedLabels().then((res) => {
        if (res.data.length === 0) {
          showAlert("error", 
            "No photo import configuration supported labels found."
          );
          handleNavigateTo(null, "/monitoring-location-time-series");
        } else {
          setSupportedLabels(res.data);
        }
      });
      localStorage.setItem(
        LocalStorageKeys.DisableOfflineDateTime,
        moment.utc().format()
      );
      getPhotoImportHistoryResults(
        photoImportHistoryId
      ).then((res) => {
        var count = 0;
        for (const [key, values] of Object.entries(res.data)) {
          count += values.length;
        }
        setPhotoCount(count);
        for (const [key, values] of Object.entries(res.data)) {
          var firstLabel = values.find((x) => x.labels.length > 0);
          let objToAdd = {
            dateTime: key,
            hasMatch: values.some((x) => x.labels.length > 0),
            customLabelConfidence: _.round(
              _.mean(values.map((x) => x.customLabelConfidence)),
              2
            ),
            animalConfidence: _.round(
              _.mean(values.map((x) => x.animalConfidence)),
              2
            ),
            values: [],
            labelName: firstLabel ? firstLabel.labels[0].labelName : "",
            labelCount: firstLabel ? firstLabel.labels.length : 0,
          };
          downloadImages(values, objToAdd);
        }
      });
    }
    return () => {
      results.forEach((result) => {
        result.values.forEach((value) => {
          if (value.imageUrl) URL.revokeObjectURL(value.imageUrl);
        });
      });
    };
  }, [photoImportHistoryId]);
  const handleNavigateTo = (url) => {
    history.push(url);
  };
  const handlePhotoClick = (imageUrl) => {
    setFullSizeImage(imageUrl);
  };
  const handleInputChange = (e, dateTime) => {
    var existingResults = [...results];
    var existingResultIndex = existingResults.findIndex(
      (x) => x.dateTime === dateTime
    );
    if (e.target.name === "labelCount") {
      existingResults[existingResultIndex].labelCount = e.target.value;
    }
    if (e.target.name === "labelName") {
      existingResults[existingResultIndex].labelName = e.target.value;
    }
    setResults(existingResults);
  };

  const handleSubmitReview = () => {
    setIsSubmiting(true);
    var resultsToSubmit = results.map((x) => {
      return {
        fileDate: x.dateTime,
        labelName: x.labelName,
        labelCount: x.labelCount,
        temp: _.round(_.mean(x.values.map((a) => _.toNumber(a.temp)))),
        filePaths: x.values.map((a) => a.filePath),
      };
    });
    submitTimeseriesReview(
      photoImportHistoryId,
      resultsToSubmit
    )
      .then((res) => {
        setIsSubmiting(false);
        handleNavigateTo("/monitoring?view=timeseries");
      })
      .catch((err) => {
        setIsSubmiting(false);
        showAlert("error", err.response.data.message);
      });
    // submit resultsToSubmit
  };

  return (
    <>
      <Paper>
        <Grid.Row className="ml-0 mr-0">
          <Grid.Col lg={12} width={12} className="">
            <div className="d-flex">
              <Typography
                variant="h3"
                className="event-number event-number-title"
                component="div"
              >
                Review Analyzed Photos
              </Typography>
              <Button
                variant="contained"
                className="mt-3 ml-5"
                onClick={() => {
                  setShowSubmitDialog(true);
                }}
              >
                Submit Review
              </Button>
            </div>
          </Grid.Col>

          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className="events-link"
                onClick={(e) => handleNavigateTo("/monitoring?view=timeseries")}
              >
                Timeseries
              </Link>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>

      <Grid.Row className="mt-2 p-2">
        {_.orderBy(
          results,
          ["hasMatch", "customLabelConfidence", "animalConfidence"],
          ["desc", "desc", "desc"]
        ).map((result, i) => (
          <PhotoCarousel
            handlePhotoClick={handlePhotoClick}
            handleInputChange={handleInputChange}
            supportedLabels={supportedLabels}
            result={result}
          />
        ))}
      </Grid.Row>

      {showSubmitDialog && (
        <LynxDialog
          open={showSubmitDialog}
          handleClose={() => {
            setShowSubmitDialog(false);
          }}
          handleConfirm={() => {
            handleSubmitReview();
          }}
          title={`Submit Review?`}
          description={`${
            results.length
          } timeseries results will be created including ${
            results.filter((x) => x.labelName && x.labelCount > 0).length
          } observations.`}
        />
      )}
      {photosLoading && (
        <LynxDialog
          open={photosLoading}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
          title={"Loading Photos..."}
        />
      )}
      {isSubmiting && (
        <LynxDialog
          open={isSubmiting}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
          title={"Submitting Results"}
        />
      )}
      {fullSizeImage != null && (
        <LynxDialog
          closeOnBackdropClick
          open={fullSizeImage}
          maxWidth={"xl"}
          handleClose={() => {
            setFullSizeImage(null);
          }}
          isCloseInHeader
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={fullSizeImage}
                  alt=""
                ></img>
              </div>
            </>
          }
        />
      )}
    </>
  );
};
