import { useState, useEffect } from "react";
import { Grid, Card } from "components/lynx-components";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { getInspectionForms } from "../../../../services/inspection-form-service";
export function InspectionSettings() {
  const [inspectionForms, setInspectionForms] = useState([]);
  const history = useHistory();
  useEffect(() => {
    loadInspectionForms();
  }, []);
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  const loadInspectionForms = () => {
    getInspectionForms({ onlyInspectionForms: true }).then((res) => {
      setInspectionForms(res.data);
    });
  };
  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Inspections
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Inspections</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Inspection Forms
            </Typography>
          </div>
        </Grid.Col>

        <Grid.Col md={10} width={12}>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => {
                  handleNavigateTo(null, "/settings/inspections/form-builder");
                }}
              >
                Add
              </Button>
            </Card.Header>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                columns={[
                  {
                    field: "name",
                    headerName: "Name",
                    width: 300,
                    type: "string",
                    renderCell: (params) => {
                      return (
                        <Button
                          onClick={(e) => {
                            handleNavigateTo(
                              e,
                              "/settings/inspections/form-builder/" +
                                params.row.id
                            );
                          }}
                        >
                          {params.value}
                        </Button>
                      );
                    },
                  },

                  {
                    field: "description",
                    headerName: "Description",
                    type: "string",
                    width: 500,
                  },
                ]}
                rows={inspectionForms}
                disableColumnReorder
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                disableChildrenSorting
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
