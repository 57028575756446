import FactoryIcon from "@mui/icons-material/Factory";
import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Grid } from "components/lynx-components";
import useAlert from "hooks/useAlert";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EventCustomFieldDto, EventDto, IncidentDto, RootState } from "types";
import { getCustomFields } from "../../../services/custom-fields-service";
import { dateUtil } from "../../../services/date-util";
import { getEvent } from "../../../services/events";

import { urgencyColorSwitch } from "./event-columns";
import { getStatusChip } from "./event-details-container";
import "./event-quick-view.css";
import { useSelector } from "react-redux";
import { EntityTypes } from "types/enums-ts";

interface EventQuickViewProps {
  event: EventDto & IncidentDto;
  eventId: number;
  customFields?: EventCustomFieldDto[];
  handleClose: () => void;
}

const EventQuickView: FC<EventQuickViewProps> = (props) => {
  const [event, setEvent] = useState<EventDto>(props.event);
  const [customFields, setCustomFields] = useState<EventCustomFieldDto[]>([]);
  const history = useHistory();
  const { showAlert } = useAlert();
  const organization = useSelector((state: RootState) => state.organization);

  useEffect(() => {
    if (props.eventId > 0) {
      getEvent(props.eventId).then((res) => {
        setEvent(res.data);
      });
    }
  }, [props.event, props.eventId]);

  useEffect(() => {
    if (props.customFields) {
      setCustomFields(props.customFields);
    } else {
      getCustomFields(EntityTypes.Event).then((res) => {
        setCustomFields(res.data);
      });
    }
  }, [props.customFields]);

  const handleEventNumberClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (event) {
      history.push(`/events/${event.id}`);
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(`${window.origin}/events/${event?.id}`);
    showAlert("success", "Link copied!");
  };
  return (
    <>
      <DialogContent
        className="event-quick-view-dialog"
        sx={{ padding: "10px 12px" }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Col width={10}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <Link
                  href="#"
                  onClick={handleEventNumberClick}
                  underline="hover"
                  className="mr-2"
                >
                  <Typography variant="h6" component="span">
                    {event?.eventNumber} - {event?.eventCategory}
                  </Typography>
                </Link>
                {event?.eventSubCategory && (
                  <Typography
                    className="mr-2"
                    variant="subtitle1"
                    component="span"
                  >
                    {event.eventSubCategory}
                  </Typography>
                )}
                <div>{getStatusChip(event)}</div>
              </div>
            </Grid.Col>
            <Grid.Col width={2}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <div className="ml-auto">
                  <Tooltip title="Copy link to event">
                    <IconButton onClick={handleLinkClick}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              {event?.eventDueDateTime && (
                <Typography variant="subtitle2">
                  Due:&nbsp;
                  {dateUtil.convertDateOnlyToLocal(event.eventDueDateTime)}
                </Typography>
              )}
              {event?.eventDateTime && (
                <Typography variant="subtitle2">
                  Completed:&nbsp;
                  {organization.hasDateOnlyEventDate
                    ? dateUtil.convertDateTimeToLocalDateOnly(
                        event.eventDateTime
                      )
                    : dateUtil.convertDateTimeToLocal(event.eventDateTime)}
                </Typography>
              )}
            </Grid.Col>
            <Grid.Col md={6} width={12}>
              <Typography variant="subtitle2">{event?.assetNames}</Typography>
            </Grid.Col>
          </Grid.Row>

          {event?.co2Emissions != null && event?.co2Emissions > 0 && (
            <Grid.Row>
              <Grid.Col md={6} width={12} className="mt-1">
                <FactoryIcon className="pt-1 mr-1" />
                <Typography variant="body1">
                  {_.round(event.co2Emissions, 2)} kg/CO2
                </Typography>
              </Grid.Col>
            </Grid.Row>
          )}
          <Divider className="mt-2 mb-2" />
          <Grid.Row className="mt-2">
            <Grid.Col md={6} width={12}>
              <Typography variant="body1">
                <b>Urgency:</b>&nbsp;
                <i
                  className={"fe fe-circle"}
                  style={{ color: urgencyColorSwitch(event) }}
                />
                &nbsp;{event?.urgency || "None"}
              </Typography>
            </Grid.Col>
            {event?.assignedToUserFullName && (
              <Grid.Col md={6} width={12}>
                <Typography variant="body1">
                  <b>Assigned To: </b>
                  {event.assignedToUserFullName}
                </Typography>
              </Grid.Col>
            )}
          </Grid.Row>
          {event?.plainTextDescription && (
            <Grid.Row className="mt-2">
              <Grid.Col md={12} width={12}>
                <Typography variant="body1">
                  <b>Description: </b>
                  {event.plainTextDescription}
                </Typography>
              </Grid.Col>
            </Grid.Row>
          )}
          <Divider className="mt-2 mb-2" />
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              <Typography variant="body1">
                <b>Follow up required? </b>
                {event?.followUpRequired ? "Yes" : "No"}
              </Typography>
            </Grid.Col>
            {event?.followUpDate && (
              <Grid.Col md={6} width={12}>
                <Typography component="div" variant="body1">
                  <b>Follow up date: </b>
                  {dateUtil.convertDateOnlyToLocal(event.followUpDate)}
                </Typography>
              </Grid.Col>
            )}
          </Grid.Row>
          {event?.followUpDescription && (
            <Grid.Row>
              <Grid.Col md={12} width={12}>
                <Typography variant="body1">
                  <b>Follow Up Description: </b>
                  {event.followUpDescription}
                </Typography>
              </Grid.Col>
            </Grid.Row>
          )}
          {!_.isEmpty(event?.customFieldData) && !_.isEmpty(customFields) && (
            <>
              <Divider className="mt-2 mb-2" />
              <Grid.Row>
                {Object.keys(
                  Object.fromEntries(
                    Object.entries(event.customFieldData).filter(
                      ([_, v]) => v != "" && v != null
                    )
                  )
                ).map((item, i) => (
                  <Grid.Col md={6} width={12} className="" key={i}>
                    <Typography variant="body1">
                      <b>{`${
                        customFields.find((x) => x.fieldName === item)
                          ?.fieldLabel
                      }: `}</b>
                      {customFields.find((x) => x.fieldName === item)
                        ?.fieldType === "Link" ? (
                        <Link
                          href={event.customFieldData[item]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.customFieldData[item]}
                        </Link>
                      ) : (
                        event.customFieldData[item]
                      )}
                    </Typography>
                  </Grid.Col>
                ))}
              </Grid.Row>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </>
  );
};

export default EventQuickView;
