import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  loggedIn,
  REFRESH_TOKEN_SUCCESS,
} from "../actions/auth";

export default function auth(
  state = {
    isFetching: false,
    isAuthenticated: loggedIn() ? true : false,
    requiresOrganizationSelection: false,
    organizationUsers: [],
  },
  action
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds,
      });
    case REFRESH_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: action.token ? true : false,
        requiresOrganizationSelection: action.token ? false : true,
        errorMessage: "",
        token: action.token,
        organizationUsers: action.organizationUsers,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        requiresOrganizationSelection: false,
      });
    default:
      return state;
  }
}
