import {
  Button,
  Chip,
  chipClasses,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { LynxDataGrid } from "components/data-grid/lynx-data-grid";
import { useHistory, useLocation } from "react-router-dom";
import {
  ActionDto,
  EntityAttachmentDto,
  EntityLookupDto,
  EventDto,
  IncidentDto,
  InspectionEventDto,
  LynxGridColDef,
  MonitoringEventDto,
  RootState,
} from "types";
import useAlert from "hooks/useAlert";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import { downloadAttachment } from "services/attachments";
import { dateUtil } from "services/date-util";
import { useEffect, useState } from "react";
import TagQuickCard from "../events/tag-quick-card";
import saveAs from "file-saver";
import { Download } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  useGetDocumentsByEntityTypeQuery,
  useGetDocumentsQuery,
} from "services/rtkApi/endpoints/documents";
import { EntityTypes } from "types/enums-ts";
import { LynxDialog } from "components/lynx-dialog";
import useWindowDimensions from "hooks/useWindowDimensions";
import _ from "lodash";
import { localStorageService } from "services/local-storage-service";
import queryString from "query-string";
import { hasAnyEventRole, hasAnyMonitoringRole, roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import { getEvent } from "services/events";
import { getIncident } from "services/incident-service";
import { Dimmer } from "components/lynx-components";
import EventQuickView from "../events/event-quick-view";
import IncidentQuickView from "../incidents/incident-quick-view";
import ActionQuickView from "../actions/action-quick-view";
import { getAction } from "services/action-service";
import { getInspection } from "services/inspection-event-service";
import InspectionQuickView from "../inspections/inspection-quick-view";
import MonitoringEventQuickView from "../monitoring/monitoring-event-quick-view";
import { useLazyGetMonitoringEventQuery } from "services/rtkApi/endpoints/monitoringEvents";

const lsKey = "documents_grid_view";

const getDocumentEntityOptions = () => {
  var options = [
    { entityType: "Global", label: "Global" },
    { entityType: EntityTypes.Action, label: "Actions" },
    { entityType: EntityTypes.Event, label: "Events" },
    { entityType: EntityTypes.Incident, label: "Incidents" },
    { entityType: EntityTypes.InspectionEvent, label: "Inspections" },
    { entityType: EntityTypes.MonitoringEvent, label: "Monitoring Event" },
  ];

  if (!hasAnyEventRole()) {
    options = options.filter((f) => f.entityType != EntityTypes.Event);
  }
  if (!hasAnyMonitoringRole()) {
    options = options.filter(
      (f) => f.entityType != EntityTypes.MonitoringEvent
    );
  }

  if (!roleMatch([UserRoles.InspectionsUser])) {
    options = options.filter(
      (f) => f.entityType != EntityTypes.InspectionEvent
    );
  }
  if (!roleMatch([UserRoles.IncidentsUser])) {
    options = options.filter((f) => f.entityType != EntityTypes.Incident);
  }
  

  return options;
};

const DocumentList = () => {
  const { showAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElQuickView, setAnchorElQuickView] =
    useState<null | HTMLElement>(null);
  const [selectedTag, setSelectedTag] = useState<EntityLookupDto | null>(null);
  const open = Boolean(anchorEl);
  const isOpenQuickView = Boolean(anchorElQuickView);
  const id = open ? "simple-popover" : undefined;
  const organization = useSelector((state: RootState) => state.organization);
  const history = useHistory();
  const [finalColumn, setFinalColumn] = useState<any[]>([]);
  const [showDownloading, setShowDownloading] = useState(false);
  const [gridView, setGridView] = useState("");

  const dimensions = useWindowDimensions();
  const location = useLocation();
  const [getMonitoringEvent] = useLazyGetMonitoringEventQuery();

  const [linkedEntity, setLinkedEntity] = useState<
    EventDto | InspectionEventDto | IncidentDto | ActionDto | MonitoringEventDto
  >({});

  const handleTagClick = (
    event: React.MouseEvent<HTMLElement>,
    tag: EntityLookupDto
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedTag(tag);
  };

  const handleDownloadFile = (id: number, fileName: string) => {
    setShowDownloading(true);
    downloadAttachment(id)
      .then((res) => {
        saveAs(res.data, fileName);
      })
      .catch(async (err: any) => {
        var errorString = JSON.parse(await err.response.data.text());
        showAlert("error", errorString);
      })
      .finally(() => {
        setShowDownloading(false);
      });
  };

  const columns: LynxGridColDef[] = [
    {
      field: "Actions",
      headerName: ``,
      width: 100,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <Stack direction="row" mt={0.5}>
            <Tooltip title="Edit">
              <IconButton
                onClick={() =>
                  history.push(`/documents/${params.row.entityAttachmentId}`)
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                onClick={() =>
                  handleDownloadFile(
                    params.row.entityAttachmentId,
                    params.row.fileName
                  )
                }
              >
                <Download color="primary" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
    },
    {
      field: "fileName",
      headerName: "File Name",
      width: 300,
      type: "string",
    },

    {
      field: "createdByUserFullName",
      headerName: "Uploaded By",
      width: 200,
      type: "string",
    },
    {
      field: "createdDateTimeUtc",
      headerName: "Uploaded Date Time",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "indexInAI",
      headerName: "Index in AI",
      width: 100,
      type: "boolean",
      sortable: false,
    },
    {
      field: "indexedDateTimeUtc",
      headerName: "Indexed Date Time",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 300,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        let element = <></>;
        if (params.value) {
          element = (
            <>
              {params.value.map((tag: EntityLookupDto, index: number) => (
                <Chip
                  onClick={(e) => handleTagClick(e, tag)}
                  label={tag.lookupCode}
                  variant="outlined"
                  sx={{
                    [`& .${chipClasses.icon}`]: {
                      color: "rgba(0, 0, 0, 0.26)",
                    },
                    mr: 1,
                  }}
                  key={`${index}_${tag}`}
                />
              ))}
            </>
          );
        }
        return element;
      },
    },
  ];

  const entityColumns: LynxGridColDef[] = [
    {
      field: "actions",
      headerName: ``,
      width: 60,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <Stack direction="row" mt={0.5}>
            <Tooltip title="Download">
              <IconButton
                onClick={() =>
                  handleDownloadFile(
                    params.row.entityAttachmentId,
                    params.row.fileName
                  )
                }
              >
                <Download color="primary" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "sourceNumber",
      headerName: `Source`,
      width: 100,
      type: "string",
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <Button onClick={(e) => handleSourceNumberClick(e, params.row)}>
            {params.row.sourceNumber}
          </Button>
        );
      },
    },
    {
      field: "fileName",
      headerName: "File Name",
      width: 300,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
    },
    {
      field: "createdByUserFullName",
      headerName: "Uploaded By",
      width: 200,
      type: "string",
    },
    {
      field: "createdDateTimeUtc",
      headerName: "Uploaded Date Time",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "indexInAI",
      headerName: "Index in AI",
      width: 100,
      type: "boolean",
      sortable: false,
    },
    {
      field: "indexedDateTimeUtc",
      headerName: "Indexed Date Time",
      width: 200,
      type: "dateTime",
      valueGetter: (value: any) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
  ];

  const handleGridViewChange = (e: any) => {
    setGridView(e.target.value);
    localStorageService.setLocalStorage(lsKey, e.target.value);
    history.push(`/documents?view=${_.toLower(e.target.value)}`);
  };

  const handleSourceNumberClick = (e: any, data: EntityAttachmentDto) => {
    if (gridView == EntityTypes.Event) {
      getEvent(data.eventId).then((res) => {
        setLinkedEntity(res.data);
      });
    }
    if (gridView == EntityTypes.InspectionEvent) {
      getInspection(data.inspectionEventId).then((res) => {
        setLinkedEntity(res.data);
      });
    }
    if (gridView == EntityTypes.Incident) {
      getIncident(data.incidentId).then((res) => {
        setLinkedEntity(res.data);
      });
    }
    if (gridView == EntityTypes.Action) {
      getAction(data.actionId).then((res) => {
        setLinkedEntity(res.data);
      });
    }
    if (gridView == EntityTypes.MonitoringEvent) {
      getMonitoringEvent(data.monitoringEventId).then((res: any) => {
        setLinkedEntity(res.data);
      });
    }
    setAnchorElQuickView(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElQuickView(null);
    setLinkedEntity({});
  };

  const getHeader = () => {
    return (
      <>
        <Typography
          variant="h3"
          component="div"
          className="align-self-center mr-5"
        >
          Documents
        </Typography>

        <span className=" align-self-end grid-view-select">
          <FormControl
            variant="standard"
            sx={
              dimensions.isMobile
                ? {
                    minWidth: 250,
                  }
                : {
                    marginBottom: 1,
                    marginTop: 1,
                    minWidth: 250,
                  }
            }
          >
            <InputLabel id="demo-simple-select-label">Select Entity</InputLabel>
            <Select
              value={gridView}
              onChange={handleGridViewChange}
              className="font-weight-bold"
            >
              {getDocumentEntityOptions().map((entity) => (
                <MenuItem value={entity.entityType} key={entity.entityType}>
                  {entity.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
      </>
    );
  };

  useEffect(() => {
    const values = queryString.parse(location.search);
    if (!_.isEmpty(values.view)) {
      const entityType = getDocumentEntityOptions().find(
        (f) =>
          f.entityType.toLowerCase() === values.view?.toString().toLowerCase()
      );

      if (entityType) {
        setGridView(entityType.entityType);
      }
    } else {
      var selectedGridView = localStorageService.getLocalStorage(lsKey);
      if (selectedGridView) {
        setGridView(selectedGridView);
        history.push(`/documents?view=${_.toLower(selectedGridView)}`);
      } else {
        setGridView("Global");
        history.push("/documents?view=Global");
      }
    }

    const cols = organization.featureFlags?.showInDevelopmentFeatures
      ? columns
      : columns.filter(
          (f) => f.field !== "indexInAI" && f.field !== "indexedDateTimeUtc"
        );
    setFinalColumn(cols);
  }, []);

  return (
    <>
      {gridView === "Global" ? (
        <LynxDataGrid
          columns={finalColumn}
          useQuery={useGetDocumentsQuery}
          addButtonAction={() => history.push("/upload-documents")}
          entityType={EntityTypes.Document}
          getRowId="entityAttachmentId"
          header={getHeader}
          localStorageName="documents"
        />
      ) : (
        <LynxDataGrid
          columns={entityColumns}
          useQuery={useGetDocumentsByEntityTypeQuery}
          useQueryParams={{ entityType: gridView }}
          entityType={EntityTypes.Document}
          getRowId="entityAttachmentId"
          header={getHeader}
          localStorageName="documents"
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
      >
        {selectedTag && <TagQuickCard id={selectedTag.lookupId} />}
      </Popover>
      <LynxDialog
        open={showDownloading}
        title={`Downloading attachment.`}
        description={
          <>
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          </>
        }
      />

      <Popover
        id={id}
        open={isOpenQuickView}
        anchorEl={anchorElQuickView}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Dimmer active={_.isEmpty(linkedEntity)} loader>
          {_.isEmpty(linkedEntity) && (
            <div style={{ height: 50, width: 50 }}></div>
          )}
          {!_.isEmpty(linkedEntity) &&
            gridView == EntityTypes.Event &&
            "eventNumber" in linkedEntity &&
            linkedEntity.eventNumber && (
              <EventQuickView
                eventId={linkedEntity.id || 0}
                event={linkedEntity}
                handleClose={handleClose}
              />
            )}
          {!_.isEmpty(linkedEntity) &&
            gridView == EntityTypes.Incident &&
            "incidentNumber" in linkedEntity &&
            linkedEntity.incidentNumber && (
              <IncidentQuickView
                incident={linkedEntity}
                handleClose={handleClose}
              />
            )}
          {!_.isEmpty(linkedEntity) &&
            gridView == EntityTypes.Action &&
            "actionNumber" in linkedEntity &&
            linkedEntity.actionNumber && (
              <ActionQuickView
                action={linkedEntity}
                actionId={linkedEntity.id || 0}
                handleClose={handleClose}
              />
            )}
          {!_.isEmpty(linkedEntity) &&
            gridView == EntityTypes.InspectionEvent &&
            "inspectionNumber" in linkedEntity &&
            linkedEntity.inspectionNumber && (
              <InspectionQuickView
                inspection={linkedEntity}
                inspectionEventId={linkedEntity.id || 0}
                handleClose={handleClose}
              />
            )}
          {!_.isEmpty(linkedEntity) &&
            gridView == EntityTypes.MonitoringEvent &&
            "monitoringEventNumber" in linkedEntity &&
            linkedEntity.monitoringEventNumber && (
              <MonitoringEventQuickView
                monitoringEvent={linkedEntity}
                monitoringEventId={linkedEntity.id || 0}
                handleClose={handleClose}
              />
            )}
        </Dimmer>
      </Popover>
    </>
  );
};

export default DocumentList;
